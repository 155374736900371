import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CarrerSupportComponent } from './carrer-support/carrer-support.component';
import { DevopsComponent } from './devops/devops.component';
import { FullStackComponent } from './full-stack/full-stack.component';
import { InformaticaCloudComponent } from './informatica-cloud/informatica-cloud.component';
import { LetsCreateComponent } from './lets-create/lets-create.component';
import { MoreDeatilsComponent } from './more-deatils/more-deatils.component';
import { HomeComponent } from './pages/home/home.component';
import { SalesforceCoursesComponent } from './pages/salesforce-courses/salesforce-courses.component';
import { PythonComponent } from './python/python.component';
import { ServicenowComponent } from './servicenow/servicenow.component';
import { FaqComponent } from './shared/faq/faq.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { ThanksforsubmittingComponent } from './thanksforsubmitting/thanksforsubmitting.component';
import { FullStackJavaComponent } from './full-stack-java/full-stack-java.component';
import { FullStackTestingComponent } from './full-stack-testing/full-stack-testing.component';

@NgModule({
  declarations: [
    AppComponent,
    CarrerSupportComponent,
    DevopsComponent,
    FullStackComponent,
    InformaticaCloudComponent,
    LetsCreateComponent,
    MoreDeatilsComponent,
    HomeComponent,
    SalesforceCoursesComponent,
    PythonComponent,
    ServicenowComponent,
    FaqComponent,
    FooterComponent,
    HeaderComponent,
    ThanksforsubmittingComponent,
    FullStackJavaComponent,
    FullStackTestingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
