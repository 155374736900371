import { Component, OnInit } from '@angular/core';
import { ChartOptions } from 'chart.js';

@Component({
  selector: 'app-full-stack-java',
  templateUrl: './full-stack-java.component.html',
  styleUrls: ['./full-stack-java.component.css']
})
export class FullStackJavaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  selectedTab: string = 'softwareEngineer';

  selectTab(tabName: string) {
    this.selectedTab = tabName;
  }


  
}
