import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { SalesforceCoursesComponent } from './pages/salesforce-courses/salesforce-courses.component';
import { PythonComponent } from './python/python.component';
import { InformaticaCloudComponent } from './informatica-cloud/informatica-cloud.component';
import { DevopsComponent } from './devops/devops.component';
import { ServicenowComponent } from './servicenow/servicenow.component';
import { FullStackComponent } from './full-stack/full-stack.component';
import { CarrerSupportComponent } from './carrer-support/carrer-support.component';
import { ThanksforsubmittingComponent } from './thanksforsubmitting/thanksforsubmitting.component';
import { FullStackJavaComponent } from './full-stack-java/full-stack-java.component';
import { FullStackTestingComponent } from './full-stack-testing/full-stack-testing.component';

const routes: Routes = [
  {
    path:"",
    component: HomeComponent
  },
  {
    path:"salesforce",
    component: SalesforceCoursesComponent
  },
  {
    path:"Python",
    component: PythonComponent
  },
  {
    path:"Informatica-cloud",
    component: InformaticaCloudComponent
  },
  {
    path:"devops",
    component: DevopsComponent
  },
  {
    path:"ServiceNow",
    component: ServicenowComponent
  },
  {
    path:"full-stack",
    component: FullStackComponent
  },
  {
    path:"carrer-support",
    component: CarrerSupportComponent
  },
  {
    path:"thanksforsubmitting",
    component: ThanksforsubmittingComponent
  },
  {
    path:"job-ready-full-stack-java", component : FullStackJavaComponent
  },
  {
    path : "job-ready-full-stack-testing", component : FullStackTestingComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
