<div class="row">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
          <a class="navbar-brand" routerLink="" style="margin-left: 2rem;">
              <img src="../../../assets/img/varahi360.png" alt="Logo" width="150px">
          </a>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0 mx-auto">
                  <li class="nav-item">
                      <a class="nav-link active" aria-current="page" href="" style="margin-right: 5rem;">Home</a>
                  </li>
                  <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style="color: black; margin-right: 5rem;">
                          Courses
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                          <li><a class="dropdown-item" routerLink="/salesforce">Salesforce</a></li>
                          <li><a class="dropdown-item" routerLink="/Python">Python</a></li>
                          <li><a class="dropdown-item" routerLink="/devops">DevOps</a></li>
                          <li><a class="dropdown-item" routerLink="/ServiceNow">ServiceNow</a></li>
                          <li><a class="dropdown-item" routerLink="/full-stack">Full Stack</a></li>
                          <li><a class="dropdown-item" routerLink="/Informatica-cloud">Informatica Cloud</a></li>
                      </ul>
                  </li>

                  <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style="color: black; margin-right: 5rem;">
                          Programs
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                          <li><a class="dropdown-item" routerLink="/job-ready-full-stack-java">Job Ready Program Full Stack Java</a></li>
                          <li><a class="dropdown-item" routerLink="/job-ready-full-stack-testing">Job Ready Program Full Stack Testing</a></li>
                      </ul>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="" style="margin-right: 5rem;" routerLink="/carrer-support">Career support</a>
                </li>
              </ul>
              <form class="d-flex">
                <a class="btn btn-outline-success text-white p-2" data-bs-toggle="modal" data-bs-target="#exampleModal" style="border-radius: 20px 4px; background: #1c477a; margin-right: 2rem;" href="thanksforsubmitting">Get Trained</a>
            </form>
          </div>

          <!-- Mobile Menu Toggle -->
          <!-- <input type="checkbox" id="menu-toggle" class="menu-toggle" style="display: none;"> -->
          <label for="menu-toggle" class="menu-icon" (click)="toggleMenu(true)">
              <span></span>
              <span></span>
              <span></span>
          </label>

          <!-- Sidebar Menu -->
          <div class="sidebar-menu" [ngClass]="{ 'active': isMenuOpen }">
              <!-- Close button -->
              <button class="close-btn" (click)="toggleMenu(false)">&times;</button>

              <ul class="navbar-nav">
                  <li class="nav-item">
                      <a class="nav-link" routerLink="/">Home</a>
                  </li>
                  <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle">Courses</a>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item" routerLink="/salesforce">Salesforce</a></li>
                        <li><a class="dropdown-item" routerLink="/Python">Python</a></li>
                        <li><a class="dropdown-item" routerLink="/devops">DevOps</a></li>
                        <li><a class="dropdown-item" routerLink="/ServiceNow">ServiceNow</a></li>
                        <li><a class="dropdown-item" routerLink="/full-stack">Full Stack</a></li>
                        <li><a class="dropdown-item" routerLink="/Informatica-cloud">Informatica Cloud</a></li>
                    </ul>
                  </li>
                  <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle">Programs</a>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item" routerLink="/job-ready-full-stack-java">Job Ready Program Full Stack Java</a></li>
                        <li><a class="dropdown-item" routerLink="/job-ready-full-stack-testing">Job Ready Program Full Stack Testing</a></li>
                    </ul>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" routerLink="/carrer-support">Career Support</a>
                  </li>
                  <form class="d-flex">
                    <a class="btn btn-outline-success text-white p-2" data-bs-toggle="modal" data-bs-target="#exampleModal" style="border-radius: 20px 4px; background: #1c477a; margin-right: 2rem;" href="thanksforsubmitting">Get Trained</a>
                </form>
              </ul>
          </div>
      </div>
  </nav>
</div>
<router-outlet></router-outlet>