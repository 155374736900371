import { Component, Renderer2, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
  isMenuOpen = false;
  private globalClickUnlistener!: () => void;

  constructor(private renderer: Renderer2, private router: Router) {}

  ngAfterViewInit(): void {
    // Listen for clicks outside the menu
    this.globalClickUnlistener = this.renderer.listen('document', 'click', (event: Event) => {
      const sidebarMenu = document.querySelector('.sidebar-menu');
      const toggleButton = document.querySelector('.menu-icon');

      if (
        this.isMenuOpen &&
        sidebarMenu &&
        toggleButton &&
        !sidebarMenu.contains(event.target as Node) &&
        !toggleButton.contains(event.target as Node)
      ) {
        this.toggleMenu(false);
      }
    });

    // Close the menu on navigation
    this.router.events.subscribe(() => {
      if (this.isMenuOpen) {
        this.toggleMenu(false);
      }
    });
  }

  toggleMenu(open: boolean = !this.isMenuOpen): void {
    this.isMenuOpen = open;

    const body = document.body;
    if (open) {
      body.classList.add('no-scroll'); // Optional: Prevent scrolling when the menu is open
    } else {
      body.classList.remove('no-scroll');
    }
  }

  navigateTo(route: string): void {
    this.router.navigate([route]);
    this.toggleMenu(false); // Close the menu after navigation
  }

  ngOnDestroy(): void {
    // Remove the global click listener when the component is destroyed
    if (this.globalClickUnlistener) {
      this.globalClickUnlistener();
    }
  }
}
