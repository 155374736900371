<div class="container-fluid">
    <div class="row mainContainer p-4">
        <div class="col-md-6">
            <div class="title1">
                <img src="../../assets/img/Rectangle 40097.png" alt="Icon">
                YES! You are in the right<br> place to skill yourself in<br> software profession
            </div>
            <div class="row mt-3 short-disc">
                <p>Get trained by industry Experts via Instructor- <br>led Live Online program</p>
            </div>
            <div class="row mt-3">
                <!-- Buttons in mobile view will be stacked -->
                <div class="col-md-12 d-flex justify-content-start">
                    <button class="btn btn-get-trained mx-1" data-toggle="modal" data-target="#exampleModal" type="submit">Get Trained</button>
                    <button class="btn btn-our-course mx-1" data-toggle="modal" data-target="#exampleModal" type="submit">Our Courses</button>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <img class="img-fluid" src="../../../assets/img/cou-images/main image.png" alt="Main Image">
        </div>
    </div>
    
</div>

<div class="" [ngClass]="{'fade-in': isVisible}" style="background-color:#F5FAFF;">

    <div class="text container">
    <span class="courses">COURSES</span>
    <div class="text1 justify-content-center">
        <div class="row justify-content-center">
            <span style="width: 50px; height: 4px; background: linear-gradient(90deg, #224A79 0%, #EFA1A8 100%); position: absolute; bottom: 0; z-index: 1; left: 50%; transform: translateX(-50%);"></span>
        </div>
        <span class="trending">TRENDING</span>
    </div>
    </div> 


    <div class="row coures">
        <div class="row card-box mb-5">
            <div class="col-md-6 p-2">
                <div class="card" data-toggle="modal" data-target="#exampleModal">
                    <img src="../../assets/img/image 14.png" class="card-img-top" alt="...">
                    <div class="text-center" style="margin-top: -12px;"><span class="card-details">Python AI/ML Training</span></div> 
                    <div class="card-body">
                        <p class="card-text">Python training emphasises on programming concepts and developing applications using Python's user-friendly syntax. </p>
                    </div>
                    <div class="row mb-5 text-center">
                        <div class="col-6 col-md-6 mb-3 mb-md-0" style="border-right: 2px solid #959595;">
                            <span> <img src="../../assets/img/ic_round-cloud-download.png"></span><br>
                            <span>Curriculum</span>
                        </div>
                        <div class="col-6 col-md-6">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <path
                                        d="M33.3334 19.9997C33.3334 23.536 31.9286 26.9274 29.4281 29.4278C26.9276 31.9283 23.5363 33.3331 20 33.3331C16.4638 33.3331 13.0724 31.9283 10.572 29.4278C8.07147 26.9274 6.66671 23.536 6.66671 19.9997C6.66671 16.4635 8.07147 13.0721 10.572 10.5717C13.0724 8.07117 16.4638 6.66642 20 6.66642C21.2667 6.66642 22.5 6.84975 23.6667 7.18308L26.2834 4.56642C24.2905 3.74569 22.1553 3.32656 20 3.33308C17.8113 3.33308 15.6441 3.76418 13.622 4.60176C11.5999 5.43934 9.76257 6.66699 8.21493 8.21464C5.08932 11.3402 3.33337 15.5795 3.33337 19.9997C3.33337 24.42 5.08932 28.6593 8.21493 31.7849C9.76257 33.3325 11.5999 34.5602 13.622 35.3977C15.6441 36.2353 17.8113 36.6664 20 36.6664C24.4203 36.6664 28.6595 34.9105 31.7852 31.7849C34.9108 28.6593 36.6667 24.42 36.6667 19.9997M13.1834 16.7997L10.8334 19.1664L18.3334 26.6664L35 9.99975L32.65 7.63308L18.3334 21.9497L13.1834 16.7997Z"
                                        fill="#204978" />
                                </svg>
                            </span><br>
                            <span>Enroll Now</span>
                        </div>
                    </div>                    
                      </div>     
        </div>

        <div class="col-md-6 p-2 card-box">
            <div class="card" data-toggle="modal" data-target="#exampleModal"> <!--data-toggle="modal" data-target="#exampleModal"-->
                <img src="../../assets/img/image 15.png" class="card-img-top" alt="...">
                <div class="text-center" style="margin-top: -12px;"><span class="card-details">Full Stack Developer</span></div> 
                <div class="card-body">
                    <p class="card-text">Full Stack Training focuses on both front-end and back-end development, enabling them to create complete web applications.
                </div>
                <div class="row mb-5 text-center">
                    <div class="col-6 col-md-6 mb-3 mb-md-0" style="border-right: 2px solid #959595;">
                        <span> <img src="../../assets/img/ic_round-cloud-download.png"></span><br>
                        <span>Curriculum</span>
                    </div>
                    <div class="col-6 col-md-6">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path
                                    d="M33.3334 19.9997C33.3334 23.536 31.9286 26.9274 29.4281 29.4278C26.9276 31.9283 23.5363 33.3331 20 33.3331C16.4638 33.3331 13.0724 31.9283 10.572 29.4278C8.07147 26.9274 6.66671 23.536 6.66671 19.9997C6.66671 16.4635 8.07147 13.0721 10.572 10.5717C13.0724 8.07117 16.4638 6.66642 20 6.66642C21.2667 6.66642 22.5 6.84975 23.6667 7.18308L26.2834 4.56642C24.2905 3.74569 22.1553 3.32656 20 3.33308C17.8113 3.33308 15.6441 3.76418 13.622 4.60176C11.5999 5.43934 9.76257 6.66699 8.21493 8.21464C5.08932 11.3402 3.33337 15.5795 3.33337 19.9997C3.33337 24.42 5.08932 28.6593 8.21493 31.7849C9.76257 33.3325 11.5999 34.5602 13.622 35.3977C15.6441 36.2353 17.8113 36.6664 20 36.6664C24.4203 36.6664 28.6595 34.9105 31.7852 31.7849C34.9108 28.6593 36.6667 24.42 36.6667 19.9997M13.1834 16.7997L10.8334 19.1664L18.3334 26.6664L35 9.99975L32.65 7.63308L18.3334 21.9497L13.1834 16.7997Z"
                                    fill="#204978" />
                            </svg>
                        </span><br>
                        <span>Enroll Now</span>
                    </div>
                </div>
                
            </div>


        </div>
        
    </div>
    <div class="row card-box mb-5">
        <div class="col-md-6 p-2 ">
            <div class="card" data-toggle="modal" data-target="#exampleModal">
                <img src="../../assets/img/image 8.png" class="card-img-top" alt="...">
                <div class="text-center" style="margin-top: -12px;"><span class="card-details">Salesforce Training</span></div> 
                <div class="card-body">
                    <p class="card-text">Salesforce training focuses on CRM software, Salesforce platform functionality, data management and application development.</p>
                </div>
                <div class="row mb-5 text-center">
                    <div class="col-6 col-md-6 mb-3 mb-md-0" style="border-right: 2px solid #959595;">
                        <span> <img src="../../assets/img/ic_round-cloud-download.png"></span><br>
                        <span>Curriculum</span>
                    </div>
                    <div class="col-6 col-md-6">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path
                                    d="M33.3334 19.9997C33.3334 23.536 31.9286 26.9274 29.4281 29.4278C26.9276 31.9283 23.5363 33.3331 20 33.3331C16.4638 33.3331 13.0724 31.9283 10.572 29.4278C8.07147 26.9274 6.66671 23.536 6.66671 19.9997C6.66671 16.4635 8.07147 13.0721 10.572 10.5717C13.0724 8.07117 16.4638 6.66642 20 6.66642C21.2667 6.66642 22.5 6.84975 23.6667 7.18308L26.2834 4.56642C24.2905 3.74569 22.1553 3.32656 20 3.33308C17.8113 3.33308 15.6441 3.76418 13.622 4.60176C11.5999 5.43934 9.76257 6.66699 8.21493 8.21464C5.08932 11.3402 3.33337 15.5795 3.33337 19.9997C3.33337 24.42 5.08932 28.6593 8.21493 31.7849C9.76257 33.3325 11.5999 34.5602 13.622 35.3977C15.6441 36.2353 17.8113 36.6664 20 36.6664C24.4203 36.6664 28.6595 34.9105 31.7852 31.7849C34.9108 28.6593 36.6667 24.42 36.6667 19.9997M13.1834 16.7997L10.8334 19.1664L18.3334 26.6664L35 9.99975L32.65 7.63308L18.3334 21.9497L13.1834 16.7997Z"
                                    fill="#204978" />
                            </svg>
                        </span><br>
                        <span>Enroll Now</span>
                    </div>
                </div>
                
                  </div>     
    </div>

    <div class="col-md-6 p-2 card-box">
        <div class="card" data-toggle="modal" data-target="#exampleModal">
            <img src="../../assets/img/image 13.png" class="card-img-top" alt="...">
           <div class="text-center" style="margin-top: -12px;"><span class="card-details">Devops Training</span></div> 
            <div class="card-body">
                <p class="card-text">DevOps traning emphasizes on integrating development, operations, automations, and continuous delivery collabration</p>
            </div>
            <div class="row mb-5 text-center">
                <div class="col-6 col-md-6 mb-3 mb-md-0" style="border-right: 2px solid #959595;">
                    <span> <img src="../../assets/img/ic_round-cloud-download.png"></span><br>
                    <span>Curriculum</span>
                </div>
                <div class="col-6 col-md-6">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path
                                d="M33.3334 19.9997C33.3334 23.536 31.9286 26.9274 29.4281 29.4278C26.9276 31.9283 23.5363 33.3331 20 33.3331C16.4638 33.3331 13.0724 31.9283 10.572 29.4278C8.07147 26.9274 6.66671 23.536 6.66671 19.9997C6.66671 16.4635 8.07147 13.0721 10.572 10.5717C13.0724 8.07117 16.4638 6.66642 20 6.66642C21.2667 6.66642 22.5 6.84975 23.6667 7.18308L26.2834 4.56642C24.2905 3.74569 22.1553 3.32656 20 3.33308C17.8113 3.33308 15.6441 3.76418 13.622 4.60176C11.5999 5.43934 9.76257 6.66699 8.21493 8.21464C5.08932 11.3402 3.33337 15.5795 3.33337 19.9997C3.33337 24.42 5.08932 28.6593 8.21493 31.7849C9.76257 33.3325 11.5999 34.5602 13.622 35.3977C15.6441 36.2353 17.8113 36.6664 20 36.6664C24.4203 36.6664 28.6595 34.9105 31.7852 31.7849C34.9108 28.6593 36.6667 24.42 36.6667 19.9997M13.1834 16.7997L10.8334 19.1664L18.3334 26.6664L35 9.99975L32.65 7.63308L18.3334 21.9497L13.1834 16.7997Z"
                                fill="#204978" />
                        </svg>
                    </span><br>
                    <span>Enroll Now</span>
                </div>
            </div>
            
   </div>
 </div>
    
</div>
<div class="row card-box ">
    <div class="col-md-6 p-2 ">
        <div class="card" data-toggle="modal" data-target="#exampleModal">
            <img src="../../assets/img/image 12.png" class="card-img-top" alt="...">
            <div class="text-center" style="margin-top: -12px;"><span class="card-details">Servicenow training</span></div> 

            <div class="card-body">
                <p class="card-text">ServiceNow Training focuses on both front-end and back-end development, enabling them to create complete web applications.</p>
            </div>
            <div class="row mb-5 text-center">
                <div class="col-6 col-md-6 mb-3 mb-md-0" style="border-right: 2px solid #959595;">
                    <span> <img src="../../assets/img/ic_round-cloud-download.png"></span><br>
                    <span>Curriculum</span>
                </div>
                <div class="col-6 col-md-6">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path
                                d="M33.3334 19.9997C33.3334 23.536 31.9286 26.9274 29.4281 29.4278C26.9276 31.9283 23.5363 33.3331 20 33.3331C16.4638 33.3331 13.0724 31.9283 10.572 29.4278C8.07147 26.9274 6.66671 23.536 6.66671 19.9997C6.66671 16.4635 8.07147 13.0721 10.572 10.5717C13.0724 8.07117 16.4638 6.66642 20 6.66642C21.2667 6.66642 22.5 6.84975 23.6667 7.18308L26.2834 4.56642C24.2905 3.74569 22.1553 3.32656 20 3.33308C17.8113 3.33308 15.6441 3.76418 13.622 4.60176C11.5999 5.43934 9.76257 6.66699 8.21493 8.21464C5.08932 11.3402 3.33337 15.5795 3.33337 19.9997C3.33337 24.42 5.08932 28.6593 8.21493 31.7849C9.76257 33.3325 11.5999 34.5602 13.622 35.3977C15.6441 36.2353 17.8113 36.6664 20 36.6664C24.4203 36.6664 28.6595 34.9105 31.7852 31.7849C34.9108 28.6593 36.6667 24.42 36.6667 19.9997M13.1834 16.7997L10.8334 19.1664L18.3334 26.6664L35 9.99975L32.65 7.63308L18.3334 21.9497L13.1834 16.7997Z"
                                fill="#204978" />
                        </svg>
                    </span><br>
                    <span>Enroll Now</span>
                </div>
            </div>
            
              </div>     
</div>

<div class="col-md-6 p-2 card-box">
    <div class="card" data-toggle="modal" data-target="#exampleModal">
        <img src="../../assets/img/image 10.png" class="card-img-top" alt="...">
        <div class="text-center" style="margin-top: -12px;"><span class="card-details">Informatica-cloud</span></div> 
        <div class="card-body">
            <p class="card-text">Informatica Cloud is an on-demand subscription service that provides a complete platform for cloud integration and data management.</p>
        </div>
        <div class="row mb-5 text-center">
            <div class="col-6 col-md-6 mb-3 mb-md-0" style="border-right: 2px solid #959595;">
                <span> <img src="../../assets/img/ic_round-cloud-download.png"></span><br>
                <span>Curriculum</span>
            </div>
            <div class="col-6 col-md-6">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <path
                            d="M33.3334 19.9997C33.3334 23.536 31.9286 26.9274 29.4281 29.4278C26.9276 31.9283 23.5363 33.3331 20 33.3331C16.4638 33.3331 13.0724 31.9283 10.572 29.4278C8.07147 26.9274 6.66671 23.536 6.66671 19.9997C6.66671 16.4635 8.07147 13.0721 10.572 10.5717C13.0724 8.07117 16.4638 6.66642 20 6.66642C21.2667 6.66642 22.5 6.84975 23.6667 7.18308L26.2834 4.56642C24.2905 3.74569 22.1553 3.32656 20 3.33308C17.8113 3.33308 15.6441 3.76418 13.622 4.60176C11.5999 5.43934 9.76257 6.66699 8.21493 8.21464C5.08932 11.3402 3.33337 15.5795 3.33337 19.9997C3.33337 24.42 5.08932 28.6593 8.21493 31.7849C9.76257 33.3325 11.5999 34.5602 13.622 35.3977C15.6441 36.2353 17.8113 36.6664 20 36.6664C24.4203 36.6664 28.6595 34.9105 31.7852 31.7849C34.9108 28.6593 36.6667 24.42 36.6667 19.9997M13.1834 16.7997L10.8334 19.1664L18.3334 26.6664L35 9.99975L32.65 7.63308L18.3334 21.9497L13.1834 16.7997Z"
                            fill="#204978" />
                    </svg>
                </span><br>
                <span>Enroll Now</span>
            </div>
        </div>
        
    </div>


</div>

</div>
    
    </div>
       
</div>
    
    
            <div class="container"  style="width: 100%;height: 100%;display: flex; max-width: max-content;">
<span  class="mobile-only mt-5">TRANSFORMATION</span>
                <img src="../../../assets/img/4949444_19721 1 (1).png" class="img-fluid mt-5" alt="Banner Image">
            </div>
    
    



<div class="text container mt-5 position-relative">
    <span style="text-align: center; display: block; z-index: 2; position: relative;font-size: 24px;">KEY FEATURES</span>
    <div class="text1 justify-content-center">
        <div class="row justify-content-center">
            <span style="width: 50px; height: 4px; background: linear-gradient(90deg, #224A79 0%, #EFA1A8 100%); position: absolute; bottom: 0; z-index: 1; left: 50%; transform: translateX(-50%);"></span>
        </div>
        <span  class="mobile-only keytrans">TRANSFORMATION</span>
    </div>
    </div> 

    <div class="row mt-5 mb-3 key-fet" style="justify-content: center;">
        <!-- Your key features content -->
        <div id="comelets">Come lets transform your skills from scratch to anything in the software profession. With our learn digital platform, here you feel that you are not preparing for training , here you can feel the corporate culture enhancing the capabilities how IT industry grows and finally you sustain in software profession.</div>
    </div>

<app-lets-create></app-lets-create>
<app-more-deatils></app-more-deatils>


<!-- Button trigger modal -->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" style="width: 100%; height: 100%; background: #1E4878; border-top-left-radius: 10px; border-top-right-radius: 4px">
          <h5 class="modal-title" id="exampleModalLabel" style="color: white; font-size: 22px; font-family: Montserrat; font-weight: 500; word-wrap: break-word; align-items: center;">Get Trained</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" style="color: white;">&times;</span>
          </button>
        </div>
        <form>
           <div class=" mt-3 form-group" style="margin-left: 50px;margin-right: 50px;">
              <label for="Name" class="col-form-label">Name</label>
              <input type="text" class="form-control" id="message-text" placeholder="Enter your name" style="width: 100%; background: #F3F7F9">
            </div>
            <div class=" form-group" style="margin-left: 50px;margin-right: 50px;">
              <label for="Name" class="col-form-label">Email</label>
              <input type="text" class="form-control" id="message-text" placeholder="Enter your Email" style="width: 100%; background: #F3F7F9">
            </div>
            <div class=" form-group" style="margin-left: 50px;margin-right: 50px;">
              <label for="Name" class="col-form-label">Phone</label>
              <input type="text" class="form-control" id="message-text" placeholder="Enter your phone number" style="width: 100%; background: #F3F7F9">
            </div>
            <div  style="color: #3D434F; font-size: 16px; font-family: Montserrat; font-weight: 500; word-wrap: break-word; margin-left: 50PX;">Our Course Adviser will reach you soon. <img src="../../../assets/img/noto_smiling-face.png"> </div>
        </form>
        <div  class=" mt-4 mb-4 submit-btn" style="font-size: 20px;
        font-family: Montserrat;
        font-weight: 500;
        word-wrap: break-word;">
          <button  type="button" class="btn btn-secondary" data-dismiss="modal" style="width: fit-content; margin-left: 50px;" routerLink="thanksforsubmitting">Submit</button>
        </div>
          
        
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>