<div class="row mt-5 mb-3 p-5 lets-create">

    <div class="lets-create-text fw-normal">Lets create a measurable<br>
        impact on your profession<br><br>
        <span class="btn keep-in-touch" data-toggle="modal" data-target="#exampleModal">
            Keep In Touch
        </span>
    </div>

   

</div>

<!-- Button trigger modal -->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" style="width: 100%; height: 100%; background: #1E4878; border-top-left-radius: 10px; border-top-right-radius: 4px">
          <h5 class="modal-title" id="exampleModalLabel" style="color: white; font-size: 22px; font-family: Montserrat; font-weight: 500; word-wrap: break-word; align-items: center;">Get Trained</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" style="color: white;">&times;</span>
          </button>
        </div>
        <form>
           <div class=" mt-3 form-group" style="margin-left: 50px;margin-right: 50px;">
              <label for="Name" class="col-form-label">Name</label>
              <input type="text" class="form-control" id="message-text" placeholder="Enter your name" style="width: 100%; background: #F3F7F9">
            </div>
            <div class=" form-group" style="margin-left: 50px;margin-right: 50px;">
              <label for="Name" class="col-form-label">Email</label>
              <input type="text" class="form-control" id="message-text" placeholder="Enter your Email" style="width: 100%; background: #F3F7F9">
            </div>
            <div class=" form-group" style="margin-left: 50px;margin-right: 50px;">
              <label for="Name" class="col-form-label">Phone</label>
              <input type="text" class="form-control" id="message-text" placeholder="Enter your phone number" style="width: 100%; background: #F3F7F9">
            </div>
            <div  style="color: #3D434F; font-size: 16px; font-family: Montserrat; font-weight: 500; word-wrap: break-word; margin-left: 50PX;">Our Course Adviser will reach you soon. <img src="../../../assets/img/noto_smiling-face.png"> </div>
        </form>
        <div  class=" mt-4 mb-4 submit-btn" style="font-size: 20px;
        font-family: Montserrat;
        font-weight: 500;
        word-wrap: break-word;">
          <button  type="button" class="btn btn-secondary" data-dismiss="modal" style="width: fit-content; margin-left: 50px;" routerLink="thanksforsubmitting">Submit</button>
        </div>
          
        
      </div>
    </div>
  </div>
