import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thanksforsubmitting',
  templateUrl: './thanksforsubmitting.component.html',
  styleUrls: ['./thanksforsubmitting.component.css']
})
export class ThanksforsubmittingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
