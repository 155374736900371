import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-salesforce-courses',
  templateUrl: './salesforce-courses.component.html',
  styleUrls: ['./salesforce-courses.component.css']
})
export class SalesforceCoursesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
