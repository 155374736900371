<div class="container-fluid">
    <div class="row mainContainer">
        <div class="col-md-6">
            <div class=" title1">
                <img src="../../assets/img/Rectangle 40097.png"> Job Ready Program FullStack &nbsp; Java
            </div>
            <div class="row mt-4">
                <div class="col-md-1">
                    <span><img class="img-polygon" src="../../assets/img/Polygon 7.png"></span>
                </div>
    
                <div class="col-md-11">
                    <span class="poly-text" >
                        Fundamentals to IT & AI </span>
                </div>
    
            </div>
            <div class="row mt-3">
                <div class="col-md-1">
                    <span><img class="img-polygon" src="../../assets/img/Polygon 7.png"></span>
                </div>
    
                <div class="col-md-11">
                    <span class="poly-text">
                        Core JAVA</span>
                </div>
    
            </div>
            <div class="row mt-3">
                <div class="col-md-1">
                    <span><img class="img-polygon" src="../../assets/img/Polygon 7.png"></span>
                </div>
    
                <div class="col-md-11">
                    <span class="poly-text">
                        DS & Algorithms </span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-1">
                    <span><img class="img-polygon" src="../../assets/img/Polygon 7.png"></span>
                </div>
    
                <div class="col-md-11">
                    <span class="poly-text">
                        Foundations of Web ( HTML,CSS, JS,TS) </span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-1">
                    <span><img class="img-polygon" src="../../assets/img/Polygon 7.png"></span>
                </div>
    
                <div class="col-md-11">
                    <span class="poly-text">
                        React Framework</span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-1">
                    <span><img class="img-polygon" src="../../assets/img/Polygon 7.png"></span>
                </div>
    
                <div class="col-md-11">
                    <span class="poly-text">
                        Advanced JAVA </span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-1">
                    <span><img class="img-polygon" src="../../assets/img/Polygon 7.png"></span>
                </div>
    
                <div class="col-md-11">
                    <span class="poly-text">
                        SpringBoot & MicroServices </span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-1">
                    <span><img class="img-polygon" src="../../assets/img/Polygon 7.png"></span>
                </div>
    
                <div class="col-md-11">
                    <span class="poly-text">
                        DevOps & Cloud </span>
                </div>
            </div>

            <div class="row mt-3 ms-5"> 
                <div class="col-md-11">
                    <span class="text-white">
                    <i class="fa-regular fa-circle-check text-info fw-bold"></i>
                    Realtime ClassRoom Training </span>
                </div>
            </div>
            <div class="row mt-3 ms-5"> 
                <div class="col-md-11">
                    <span class="text-white">
                    <i class="fa-regular fa-circle-check text-info fw-bold"></i>
                    Project and Task Based </span>
                </div>
            </div>
            <div class="row mt-3 ms-5"> 
                <div class="col-md-11">
                    <span class="text-white">
                    <i class="fa-regular fa-circle-check text-info fw-bold"></i>
                    6 to 8 Hrs Every Day </span>
                </div>
            </div>
            <div class="row mt-3 ms-5"> 
                <div class="col-md-11">
                    <span class="text-white">
                    <i class="fa-regular fa-circle-check text-info fw-bold"></i>
                    Interviews, Jobs and Placement Support</span>
                </div>
            </div>
            <div class="row mt-3 ms-5"> 
                <div class="col-md-11">
                    <span class="text-white">
                    <i class="fa-regular fa-circle-check text-info fw-bold"></i>
                    Communication Skills & Personality Development </span>
                </div>
            </div>
            <div class="row mt-3 ms-5"> 
                <div class="col-md-11">
                    <span class="text-white">
                    <i class="fa-regular fa-circle-check text-info fw-bold"></i>
                    Interview Preparations </span>
                </div>
            </div>

            <div class="row mt-5 text-center">
                <!-- Students Enrolled -->
                <div class="col-md-4 mb-3">
                  <i class="fa-solid fa-user-plus me-2 text-white"></i>
                  <span class="text-white">50,000+ Students Enrolled</span>
                </div>
                
                <!-- Ratings -->
                <div class="col-md-4 mb-3">
                  <i class="bi bi-person-add me-2 text-white"></i>
                  <span class="text-white">4.5 Ratings</span>
                  <i class="fa-solid fa-star text-warning"></i>
                  <i class="fa-solid fa-star text-warning"></i>
                  <i class="fa-solid fa-star text-warning"></i>
                  <i class="fa-solid fa-star text-warning"></i>
                  <i class="fa-solid fa-star-half text-warning"></i>
                  <span class="text-white">(500)</span>
                </div>
                
                <!-- Duration -->
                <div class="col-md-4 mb-3">
                  <i class="fa-regular fa-clock me-2 text-white"></i>
                  <span class="text-white">6 months Duration</span>
                </div>
              </div>
              
            

            <div class="row mt-3">
                <div class="col-md-4 d-flex justify-content-start">
                    <button class="btn btn-get-trained mx-1" data-toggle="modal" data-target="#exampleModal" type="submit">Schedule Online Demo</button>
                </div>
                <div class="col-md-6 d-flex justify-content-start">
                    <button class="btn btn-our-course mx-1 ms-5" data-toggle="modal" data-target="#exampleModal" type="submit">Contact Course Adviser</button>
                </div>
            </div>
            
            
            
            
            
            
        </div>
        <div class="col-md-6 mb-5">
            <img class="img-fluid mb-5" src="img-fluid" src="../../assets/img/fullstack_java.png" alt="Main Image">
        </div>
    </div>
</div>


<div class="container my-5">
    <h2 class="text-center mb-4">Why Job Ready Program FullStack Java With Digital Lync?</h2>
    <ul class="nav nav-tabs justify-content-center">
      <li class="nav-item">
        <button class="nav-link" [class.active]="selectedTab === 'softwareEngineer'" (click)="selectTab('softwareEngineer')">
          Software Engineer
        </button>
      </li>
      <li class="nav-item">
        <button class="nav-link" [class.active]="selectedTab === 'frontEnd'" (click)="selectTab('frontEnd')">
          FrontEnd Developer
        </button>
      </li>
      <li class="nav-item">
        <button class="nav-link" [class.active]="selectedTab === 'javaDeveloper'" (click)="selectTab('javaDeveloper')">
          Java Developer
        </button>
      </li>
      <li class="nav-item">
        <button class="nav-link" [class.active]="selectedTab === 'fullStack'" (click)="selectTab('fullStack')">
          FullStack Developer
        </button>
      </li>
      <li class="nav-item">
        <button class="nav-link" [class.active]="selectedTab === 'applicationEngineer'" (click)="selectTab('applicationEngineer')">
          Application Engineer
        </button>
      </li>
    </ul>
  
    <div class="tab-content mt-4">
      <div *ngIf="selectedTab === 'softwareEngineer'" class="row">
        <div class="col-md-4 text-center tab-shadow me-5">
          <h3>₹ 8 LPA</h3>
          <p>Avg package</p>
          <h3>44 %</h3>
          <p>Avg hike</p>
          <h3>3000 +</h3>
          <p>Tech transitions</p>
        </div>
        <div class="col-md-4 text-center tab-shadowc">
          <h5>Annual Average Salaries</h5>
          <div class="chart-placeholder">[Insert Chart Here]</div>
        </div>
        <div class="col-md-4 text-center tab-shadow ms-5">
            <h3>Demand</h3>
          <img src="../../assets/img/demand.webp" alt="Demand" class="img-fluid" />
          <h3>87%</h3>
          <p>Managers said hiring Job Ready Program was top priority</p>
        </div>
      </div>

      <div *ngIf="selectedTab === 'frontEnd'" class="row">
        <div class="col-md-4 text-center tab-shadow me-5">
          <h3>₹ 9 LPA</h3>
          <p>Avg package</p>
          <h3>46 %</h3>
          <p>Avg hike</p>
          <h3>4000 +</h3>
          <p>Tech transitions</p>
        </div>
        <div class="col-md-4 text-center tab-shadowc">
          <h5>Annual Average Salaries</h5>
          <div class="chart-placeholder">[Insert Chart Here]</div>
        </div>
        <div class="col-md-4 text-center tab-shadow ms-5">
            <h3>Demand</h3>
          <img src="../../assets/img/demand.webp" alt="Demand" class="img-fluid" />
          <h3>87%</h3>
          <p>Managers said hiring Job Ready Program was top priority</p>
        </div>
      </div>

      <div *ngIf="selectedTab === 'javaDeveloper'" class="row">
        <div class="col-md-4 text-center tab-shadow me-5">
          <h3>₹ 10 LPA</h3>
          <p>Avg package</p>
          <h3>48 %</h3>
          <p>Avg hike</p>
          <h3>2000 +</h3>
          <p>Tech transitions</p>
        </div>
        <div class="col-md-4 text-center tab-shadowc">
          <h5>Annual Average Salaries</h5>
          <div class="chart-placeholder">[Insert Chart Here]</div>
        </div>
        <div class="col-md-4 text-center tab-shadow ms-5">
            <h3>Demand</h3>
          <img src="../../assets/img/demand.webp" alt="Demand" class="img-fluid" />
          <h3>87%</h3>
          <p>Managers said hiring Job Ready Program was top priority</p>
        </div>
      </div>

      <div *ngIf="selectedTab === 'fullStack'" class="row">
        <div class="col-md-4 text-center tab-shadow me-5">
          <h3>₹ 9 LPA</h3>
          <p>Avg package</p>
          <h3>48 %</h3>
          <p>Avg hike</p>
          <h3>3000 +</h3>
          <p>Tech transitions</p>
        </div>
        <div class="col-md-4 text-center tab-shadowc">
          <h5>Annual Average Salaries</h5>
          <div class="chart-placeholder">[Insert Chart Here]</div>
        </div>
        <div class="col-md-4 text-center tab-shadow ms-5">
            <h3>Demand</h3>
          <img src="../../assets/img/demand.webp" alt="Demand" class="img-fluid" />
          <h3>87%</h3>
          <p>Managers said hiring Job Ready Program was top priority</p>
        </div>
      </div>

      <div *ngIf="selectedTab === 'applicationEngineer'" class="row">
        <div class="col-md-4 text-center tab-shadow me-5">
          <h3>₹ 8 LPA</h3>
          <p>Avg package</p>
          <h3>44 %</h3>
          <p>Avg hike</p>
          <h3>3000 +</h3>
          <p>Tech transitions</p>
        </div>
        <div class="col-md-4 text-center tab-shadowc">
          <h5>Annual Average Salaries</h5>
          <div class="chart-placeholder">[Insert Chart Here]</div>
        </div>
        <div class="col-md-4 text-center tab-shadow ms-5">
            <h3>Demand</h3>
          <img src="../../assets/img/demand.webp" alt="Demand" class="img-fluid" />
          <h3>87%</h3>
          <p>Managers said hiring Job Ready Program was top priority</p>
        </div>
      </div>
  
    </div>

    <div class="container text-center my-5">
      <h2 class="mb-4 font-weight-bold">Our Alumni Work at Top Companies</h2>
    
      <!-- Above pictures -->
      <div class="row justify-content-center">
        <div class="col-6 col-md-2 mb-5 me-2">
          <img src="../../assets/img/adobe.webp" class="img-fluid logo" alt="Adobe Logo">
        </div>
        <div class="col-6 col-md-2 mb-5 me-2">
          <img src="../../assets/img/L&T.webp" class="img-fluid logo" alt="L&T Infotech Logo">
        </div>
        <div class="col-6 col-md-2 mb-5 me-2">
          <img src="../../assets/img/cog.webp" class="img-fluid logo" alt="Cognizant Logo">
        </div>
        <div class="col-6 col-md-2 mb-5 me-2">
          <img src="../../assets/img/micro.webp" class="img-fluid logo" alt="Microsoft Logo">
        </div>
        <div class="col-6 col-md-2 mb-5 me-2">
          <img src="../../assets/img/infosys.webp" class="img-fluid logo" alt="Infosys Logo">
        </div>
      </div>
    
      <!-- Below pictures -->
      <div class="row justify-content-center">
        <div class="col-6 col-md-2 mb-3">
          <img src="../../assets/img/tcs.webp" class="img-fluid logo" alt="TCS Logo">
        </div>
        <div class="col-6 col-md-2 mb-3">
          <img src="../../assets/img/hcl.webp" class="img-fluid logo" alt="HCL Logo">
        </div>
        <div class="col-6 col-md-2 mb-3">
          <img src="../../assets/img/mphaes.webp" class="img-fluid logo" alt="Mphasis Logo">
        </div>
        <div class="col-6 col-md-2 mb-3">
          <img src="../../assets/img/wipro.webp" class="img-fluid logo" alt="Wipro Logo">
        </div>
        <div class="col-6 col-md-2 mb-3">
          <img src="../../assets/img/techM.webp" class="img-fluid logo" alt="Tech Mahindra Logo">
        </div>
        <div class="col-6 col-md-2 mb-3">
          <img src="../../assets/img/cyient.webp" class="img-fluid logo" alt="Cyient Logo">
        </div>
      </div>
    </div>

    <div class="curriculum-section">
      <p class="text-center fw-bold">Explore the Varahi Digital Way</p>
      <div class="step-container">
        <div class="step-number">1</div>
        <h3 class="step-title fw-bold">Learn</h3>
        <p class="step-description">
          Learn from Curated Curriculums <span style="color: #3366cc;">developed by Industry Experts</span>
        </p>
      </div>
      
      <div class="curriculum-heading">
        <h2>Job Ready Program FullStack Java Course Curriculum</h2>
        <p class="curriculum-subheading">
          It Stretches Your Mind, Think Better And Create Even Better.
        </p>
      </div>
    
      <div class="button-container">
        <button class="cta-button w-75 fw-bold">Fundamentals of IT</button>
      </div>
    </div>

    <div class="container mt-3">
      <div class="accordion" id="accordionExample">
        <!-- Accordion Item 1 -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              <span class="module-badge me-2">Module 1</span>
              Applications & Web Technologies
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <h5 class="fw-bold">Topics:</h5>
              <p class="fw-bold h6">1. What is an Application?</p>
              <p class="h6 ms-3">
                <i class="fa-solid fa-star me-2"></i>Overview of applications and
                their significance.
              </p>
            </div>
          </div>
        </div>
        <!-- Accordion Item 2 -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <span class="module-badge me-2">Module 2</span>
              Software Development Life Cycle (SDLC)
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <h5 class="fw-bold">Topics:</h5>
              <p class="fw-bold h6">1. Understanding the SDLC.</p>
              <p class="h6 ms-3">
                <i class="fa-solid fa-star me-2"></i>Stages of SDLC and their importance.
              </p>
            </div>
          </div>
        </div>
        <!-- Accordion Item 3 -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <span class="module-badge me-2">Module 3</span>
              Agile and Scrum
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <h5 class="fw-bold">Topics:</h5>
              <p class="fw-bold h6">1. Introduction to Agile and Scrum.</p>
              <p class="h6 ms-3">
                <i class="fa-solid fa-star me-2"></i>Principles and benefits of Agile methodology.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
         

  </div>
  

  
  