<div class="row" style=" height:480px; background:#F5F9FD;">

    <div class="col-md-4"></div>
    <div class="col-md-4"> <img  class="img-thankyou" src="../../assets/img/thank you.png"></div>
    <div class="col-md-4"></div>

   

</div >

<div class="row mt-5">

    <div  class="h1">For showing interest in our courses</div>

<div  class="h2" >Our Course advisor will reach you shortly </div>

<div  class="h3" >Go to Home Page <span class="greaterthan"> &#62;</span> </div>

</div>



<app-more-deatils></app-more-deatils>

