
<div class="row  main-pic"
style="background: url(../../assets/img/Rectangle\ 40084.png), lightgray 50% / cover no-repeat; width: 100%; height: 500px;">
<div class="col-md-6 p-5  "
    style="line-height: 2.2; background: linear-gradient(90deg, #18385D 28.32%, rgba(0, 0, 0, 0.00) 103.15%); height: 500px;">
    <div class="main-title mt-5"> Learn Digital</div>
    <div class=" title1">
        <img src="../../assets/img/Rectangle 40097.png"> Career Support
    </div>
    <div class="row mt-3 short-disc">
        <p>Lorem ipsum dolor sit amet consectetur. <br>Urna diam turpis gravida ullamcorper <br>amet. Placerat
            aliquam eleifend proin </p>
    </div>
</div>
<div class="col-md-6"></div>

</div>

<span class="text-center " style=" background: white;position: relative;
left: 5%; margin-top: -30px;"   >
<div class="row mb-3 data p-1" style="justify-content: center;width:90%">
    <div class="col-md-3 p-3">
        <div class="data-num"> 75.1k + </div>
        <div class="data-name"> Happy Students</div>
    </div>
    <div class="col-md-3 p-3">
        <div class="data-num"> 1000 + </div>
        <div class="data-name">Internships <br> placements</div>
    </div>
    <div class="col-md-3 p-3">
        <div class="data-num"> 93 + </div>
        <div class="data-name"> Real Time Projects</div>
    </div>
    <div class="col-md-3 p-3">
        <div class="data-num"> 50 + </div>
        <div class="data-name"> Mentors</div>
    </div>
</div>
</span>

<div class="row desc-container  mb-5">

<div class="row mt-5" style="height:fit-content;justify-content: center;">
    <div class="col-md-6 mt-5 p-5"> <img class="img-fluid" src="../../assets/img/cou-images/Happy students image.png" style="justify-content: center;"> </div>
    <div class="col-md-6 mt-5  p-5 ">
        <div class="row mt-5 header">
            <span><b>HAPPY STUDENTS</b></span>
            <div class="row mt-2 " style="margin-left: 1rem;">
                <span style="width: 70px;
                height: 4px;
                flex-shrink: 0; background: linear-gradient(90deg, #224A79 0%, #EFA1A8 100%) "></span>
            </div>
        </div>

        <div class="row mt-4 description">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida </div>

        <div class="row mt-3 details"> Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida ullamcorper
            amet. Placerat aliquam eleifend proin iaculis sapien
            tincidunt nascetur. Nulla tempus eu purus in turpis.Placerat aliquam eleifend proin iaculis sapien
            tincidunt nascetur. Nulla tempus eu purus in turpis.</div>
        <!-- <div class="row mt-3 our-course"><button class="our-courses">Our Courses</button></div> -->
        <div class="mt-5"  style="padding-left: 30px; padding-right: 30px; padding-top: 10px; padding-bottom: 10px;  position: absolute; background: #1D4777; border-top-left-radius: 20px; border-top-right-radius: 4px; border-bottom-right-radius: 20px; border-bottom-left-radius: 4px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 10px; display: inline-flex">
            <div class="our-course" data-toggle="modal" data-target="#exampleModal">Our Courses</div>
        </div>
    </div>
</div>


<div class="row mt-5" style="height: 100%; justify-content: center;">
    <div class="col-md-6 p-5">
        <div class="row mt-5 header">
            <span><b>INTERSHIP PLACEMENTS</b></span>
            <div class="row mt-2 " style="margin-left: 1rem;">
                <span style="width: 70px;
                height: 4px;
                flex-shrink: 0; background: linear-gradient(90deg, #224A79 0%, #EFA1A8 100%) "></span>
            </div>
        </div>

        <div class="row mt-4 description">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida </div>

        <div class="row mt-3 details"> Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida ullamcorper
            amet. Placerat aliquam eleifend proin iaculis sapien
            tincidunt nascetur. Nulla tempus eu purus in turpis.Placerat aliquam eleifend proin iaculis sapien
            tincidunt nascetur. Nulla tempus eu purus in turpis.</div>
         <!-- class=" mt-3 our-course"><button class="our-courses">Our Courses</button> -->
         <div class="mt-5"  style="padding-left: 30px; padding-right: 30px; padding-top: 10px; padding-bottom: 10px;  position: absolute; background: #1D4777; border-top-left-radius: 20px; border-top-right-radius: 4px; border-bottom-right-radius: 20px; border-bottom-left-radius: 4px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 10px; display: inline-flex">
            <div class="our-course">Our Courses</div>
        </div>
    </div>
    <div class="col-md-6 p-5"><img img class="img-fluid" src="../../assets/img/cou-images/Intership image.png" style="justify-content: center;"></div>

</div>

<div class="row mt-5" style="height: 100%; justify-content: center;">
    <div class="col-md-6 p-5"> <img  img class="img-fluid" src="../../assets/img/cou-images/Real time image.png" style="justify-content: center;"> </div>
    <div class="col-md-6 p-5">
        <div class="row mt-5 header">
            <span><b>REAL TIME PROJECTS</b></span>
            <div class="row mt-2 " style="margin-left: 1rem;">
                <span style="width: 70px;
                height: 4px;
                flex-shrink: 0; background: linear-gradient(90deg, #224A79 0%, #EFA1A8 100%) "></span>
            </div>
        </div>

        <div class="row mt-4 description">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida </div>

        <div class="row mt-3 details"> Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida ullamcorper
            amet. Placerat aliquam eleifend proin iaculis sapien
            tincidunt nascetur. Nulla tempus eu purus in turpis.Placerat aliquam eleifend proin iaculis sapien
            tincidunt nascetur. Nulla tempus eu purus in turpis.</div>
        <!-- <div class="row mt-3 our-course"><button class="our-courses">Our Courses</button></div> -->
        <div class="mt-5"  style="padding-left: 30px; padding-right: 30px; padding-top: 10px; padding-bottom: 10px;  position: absolute; background: #1D4777; border-top-left-radius: 20px; border-top-right-radius: 4px; border-bottom-right-radius: 20px; border-bottom-left-radius: 4px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 10px; display: inline-flex">
            <div class="our-course" data-toggle="modal" data-target="#exampleModal">Our Courses</div>
        </div>
    </div>

</div>
<div class="row mt-5 mentors-header">
    <span class="row mt-5" style="text-align: center;"><b>MENTORS</b></span>
    <div class="row mt-3" style="justify-content: center;">
        <span style="text-align: center;width: 70px;
                height: 4px;
                flex-shrink: 0; background: linear-gradient(90deg, #224A79 0%, #EFA1A8 100%) "></span>
    </div>

</div>
<div class="row mt-5 mentors justify-content-center">
    <div class="col-md-3 col-sm-6 text-center">
        <div class="mentors-pic "> <img class="img-fluid" src="../../assets/img/mentors/Rectangle 40199.png"></div>
        <div class="mentor-name  mt-4"> Micheal </div>
        <div class="mentor-des  mt-2">Sr. Software engineer</div>
    </div>

    <div class="col-md-3 col-sm-6 text-center">
        <div class="mentors-pic"> <img class="img-fluid" src="../../assets/img/mentors/Rectangle 40196.png"></div>
        <div class="mentor-name  mt-4"> Jackson </div>
        <div class="mentor-des  mt-2">Software engineer</div>
    </div>

    <div class="col-md-3 col-sm-6 text-center">
        <div class="mentors-pic"> <img class="img-fluid" src="../../assets/img/mentors/Rectangle 40197.png"></div>
        <div class="mentor-name  mt-4"> Sophia </div>
        <div class="mentor-des  mt-2">Data Scientist</div>
    </div>

    <div class="col-md-3 col-sm-6 text-center">
        <div class="mentors-pic">  <img class="img-fluid" src="../../assets/img/mentors/Rectangle 40198.png"></div>
        <div class="mentor-name  mt-4"> Harry </div>
        <div class="mentor-des  mt-2 mb-5">Designer</div>
    </div>
</div>

<div class="row d-flex align-items-center justify-content-center mt-5 mb-5" style="height: 100%;">

    <!-- Left Column -->
    <div class="col-md-6 col-12 mb-5 certification">
        <div class="row mt-5 header mx-auto">
            <span>CERTIFICATION</span>
            <div class="row mt-2 mx-auto">
                <span></span>
            </div>
        </div>

        <div class="row p-3 description">Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida</div>

        <div class="row p-3 details">
            Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida ullamcorper amet.
            Placerat aliquam eleifend proin iaculis sapien tincidunt nascetur. Nulla tempus eu purus in turpis. Placerat
            aliquam eleifend proin iaculis sapien tincidunt nascetur. Nulla tempus eu purus in turpis.
        </div>

        <!-- Action Button -->
        <div class="action-btn" data-toggle="modal" data-target="#exampleModal">
            Get Certified
        </div>
    </div>

    <!-- Right Column -->
    <div class="col-md-6 col-12 d-flex justify-content-center align-items-center">
        <div class="certificate">
            <img src="https://w7.pngwing.com/pngs/411/516/png-transparent-certificate-of-achievement-sample-format-public-key-certificate-academic-certificate-diploma-red-wine-credentials-template-wine-glass-text.png" alt="Certificate" class="d-flex justify-content-center align-content-center">
        </div>
    </div>

</div>


<app-lets-create></app-lets-create>
<app-more-deatils></app-more-deatils>


<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header" style="width: 100%; height: 100%; background: #1E4878; border-top-left-radius: 4px; border-top-right-radius: 4px; text-align: center;" >
      <h5 class="modal-title" id="exampleModalLabel" style="color: white; font-size: 22px; font-family: Montserrat; font-weight: 500; word-wrap: break-word; align-items: center;">Get Trained</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true" style="color: white; min-height: 100px;">&times;</span>
      </button>
    </div>
    <form>
       <div class=" mt-3 form-group" style="margin-left: 50px;margin-right: 50px;">
          <label for="Name" class="col-form-label">Name</label>
          <input type="text" class="form-control" id="message-text" placeholder="Enter your name" style="width: 100%; background: #F3F7F9">
        </div>
        <div class=" form-group" style="margin-left: 50px;margin-right: 50px;">
          <label for="Name" class="col-form-label">Email</label>
          <input type="text" class="form-control" id="message-text" placeholder="Enter your Email" style="width: 100%; background: #F3F7F9">
        </div>
        <div class=" form-group" style="margin-left: 50px;margin-right: 50px;">
          <label for="Name" class="col-form-label">Phone</label>
          <input type="text" class="form-control" id="message-text" placeholder="Enter your phone number" style="width: 100%; background: #F3F7F9">
        </div>
        <div  style="color: #3D434F; font-size: 16px; font-family: Montserrat; font-weight: 500; word-wrap: break-word; margin-left: 50PX;">Our Course Adviser will reach you soon. <img src="../../../assets/img/noto_smiling-face.png"> </div>
    </form>
    <div  class=" mt-4 mb-4 submit-btn" style="font-size: 20px;
    font-family: Montserrat;
    font-weight: 500;
    word-wrap: break-word;">
      <button  type="button" class="btn btn-secondary" data-dismiss="modal" style="width: fit-content; margin-left: 50px;" routerLink="thanksforsubmitting">Submit</button>
    </div>
      
    
  </div>
</div>
</div>