import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-informatica-cloud',
  templateUrl: './informatica-cloud.component.html',
  styleUrls: ['./informatica-cloud.component.css']
})
export class InformaticaCloudComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
