import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  type : any;

  constructor() { }

  ngOnInit(): void {
    this.type = localStorage.getItem('type');
    console.log("inside Faq", this.type);
  }
  faqData = [
    {
      id: 1,
      question: 'What is Angular?',
      answer: 'Angular is a popular open-source JavaScript framework for building web applications.'
    },
    {
      id: 2,
      question: 'How do I install Angular?',
      answer: 'You can install Angular using the Angular CLI, which is a command-line tool for Angular development.'
    },
  ];


}
