<div class="container-fluid">
    <div class="row mainContainer">
        <div class="col-md-6">
            <div class=" title1">
                <img src="../../assets/img/Rectangle 40097.png" class="me-2"><span id="straining">Python Training</span>
            </div>
            <div class="row mt-4 align-items-center">
                <div class="col-auto">
                    <span><img class="img-polygon" src="../../assets/img/Polygon 7.png" alt="Polygon"></span>
                </div>
                <div class="col">
                    <span class="poly-text">
                        Fundamentals of IT
                    </span>
                </div>
            </div>
            
            <div class="row mt-3 align-items-center">
                <div class="col-auto">
                    <span><img class="img-polygon" src="../../assets/img/Polygon 7.png" alt="Polygon"></span>
                </div>
                <div class="col">
                    <span class="poly-text">
                        Basic Python
                    </span>
                </div>
            </div>
            
            <div class="row mt-3 align-items-center">
                <div class="col-auto">
                    <span><img class="img-polygon" src="../../assets/img/Polygon 7.png" alt="Polygon"></span>
                </div>
                <div class="col">
                    <span class="poly-text">
                        Advanced Python
                    </span>
                </div>
            </div>
  
            <div class="row mt-3 align-items-center">
                <div class="col-auto">
                    <span><img class="img-polygon" src="../../assets/img/Polygon 7.png" alt="Polygon"></span>
                </div>
                <div class="col">
                    <span class="poly-text">
                        Django Python Framework
                    </span>
                </div>
            </div>
  
            <div class="row mt-3 align-items-center">
                <div class="col-auto">
                    <span><img class="img-polygon" src="../../assets/img/Polygon 7.png" alt="Polygon"></span>
                </div>
                <div class="col">
                    <span class="poly-text">
                        Python for DataScience
                    </span>
                </div>
            </div>

            <div class="row mt-3 align-items-center">
                <div class="col-auto">
                    <span><img class="img-polygon" src="../../assets/img/Polygon 7.png" alt="Polygon"></span>
                </div>
                <div class="col">
                    <span class="poly-text">
                        DevOps & Cloud for Python Dev
                    </span>
                </div>
            </div>
  
            <div class="button-container">
                <button class="btn btn-get-trained mx-1" data-toggle="modal" data-target="#exampleModal" type="submit">Get Trained</button>
                <button class="btn btn-our-course mx-1" data-toggle="modal" data-target="#exampleModal" type="submit">Our Courses</button>            
            </div>
            
            
            
            <!-- <div class="row mt-3">
                <div class="col-md-3 d-flex justify-content-start">
                    <button class="btn btn-get-trained mx-1" data-toggle="modal" data-target="#exampleModal" type="submit">Get Trained</button>
                </div>
                <div class="col-md-9 d-flex justify-content-start">
                    <button class="btn btn-our-course mx-1" data-toggle="modal" data-target="#exampleModal" type="submit">Our Courses</button>
                </div>
            </div> -->
  
             </div>
        <div class="col-md-6">
            <img class="img-fluid mt-2"src="..\..\..\assets\img\cou-images\python_image.png" alt="Main Image" width="550px" id="salesforce">
        </div>
    </div>
  </div>
  
  <div class="row" style=" height: 100%; background: #F5FAFF;">
    <div class="row mt-5">
        <span class="courses">PYTHON COURES</span>
        <div class="row" style="justify-content: center;">
            <span style="text-align: center;width: 70px;
                height: 4px;
                flex-shrink: 0; background: linear-gradient(90deg, #224A79 0%, #EFA1A8 100%); z-index: 2;"></span>
        </div>
        <span class="curriculum">CURRICULUM</span>
    </div>
    <!-- <app-faq></app-faq> -->
  
     
    <div class="container mt-3">
      <div class="button-container">
        <button class="cta-button w-75 fw-bold">Fundamentals of IT</button>
      </div>
        <div class="accordion" id="accordionExample">
          <!-- Accordion Item 1 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <span class="module-badge me-2">Module 1</span>
                Applications & Web Technologies
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. What is an Application?</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Overview of applications and their significance.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Types of Applications</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Classification and examples of various application types.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Fundamentals of Web Applications</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Basic concepts and components of web applications.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Web Application Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Structure and design patterns in web application architecture.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Web Technologies used in Projects</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key technologies and frameworks used in web application development.
                      </p>
                    </div>
                  </div>
                  
              </div>
            </div>
          </div>
          <!-- Accordion Item 2 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false" aria-controls="collapseTwo">
                <span class="module-badge me-2">Module 2</span>
                Software Development Life Cycle (SDLC)
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to Software Development Life Cycle</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>The phases, importance, and overview of SDLC.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Application Lifecycle Management - ALM</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Tools, processes, and overview of ALM.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. SDLC Methodologies</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Examination of different methodologies used in software development.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. DevOps Process</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding the principles, practices, and benefits of DevOps.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Web Technologies used in Projects</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key technologies and frameworks used in web application development.
                      </p>
                    </div>
                  </div>
                  
                  
              </div>
            </div>
          </div>
          <!-- Accordion Item 3 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <span class="module-badge me-2">Module 3</span>
                Agile and Scrum
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction To Agile & Scrum</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Fundamental overview of Agile methodologies and the Scrum framework.</p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. The Principles of Agile Methodology</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Core principles of Agile focusing on iterative development and customer collaboration.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Scrum Framework: Roles, Artifacts, and Events</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key components of Scrum, including its roles, artifacts, and structured events.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Implementing Agile with Scrum</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Strategies for applying Agile and Scrum practices in software development projects.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Agile Project Management Best Practices</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Essential practices for leading Agile projects, emphasizing communication and continuous improvement.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
  
  
          <!-- Accordion item 4 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <span class="module-badge me-2">Module 4</span>
                Linux For DevOps & Cloud
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1.  Introduction to Linux OS</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Exploring the fundamentals of the Linux operating system.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Linux Distributions and Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding different distributions and the architecture of Linux.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Command Line Interface (CLI) & Filesystem</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Mastering the CLI and navigating the Linux filesystem.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. File Management and vi Editor</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Managing files and editing them using the vi editor.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Archives and Package Management</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Utilizing tar, zip utilities, and managing packages in Linux.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">5. System Installation and Package Managers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Installing software on Ubuntu, using .deb files, and the APT package manager.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">6. Users, Groups, and Permissions</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Managing users and groups, and configuring permissions.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Networking Basics: IP Address, Protocols, & Ports</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Networking Basics: IP Address, Protocols, & Ports.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Archives and Package Management</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Configuring firewalls and understanding basic security measures.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Load Balancers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Basics of load balancing in a Linux environment for optimizing performance and reliability
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 5 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                <span class="module-badge me-2">Module 5</span>
                Version Control - GIT, GITHUB
              </button>
            </h2>
            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to Version Control System</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Basics of version control and its importance in software development.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Centralised vs Distributed Version Control System</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Differences between centralized and distributed systems, with a focus on their advantages and use cases.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Git & GitHub Introduction</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Overview of Git and GitHub, and how they revolutionize code management and collaboration.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Git Workflow</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding the standard workflow in Git, including stages of code changes and commit practices.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. GitHub for Collaboration</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Using GitHub features for project collaboration, including issues, forks, and pull requests.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">6. Git Branching Model</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Strategies for branching in Git, including feature branches and the master branch.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Git Merging and Pull Requests</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Techniques for merging branches and the role of pull requests in collaborative projects.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Git Rebase</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Understanding rebase, its advantages, and how it differs from merging.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Handling Detached Head and Undoing Changes</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Managing a detached HEAD in Git and various ways to undo changes.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">10. Advanced Git Features: Git Ignore, Tagging</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Utilizing .gitignore for excluding files from tracking, and tagging for marking specific points in history.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 6 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                <span class="module-badge me-2">Module 6</span>
                Containerisation - Docker
              </button>
            </h2>
            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to Containerisation</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Essentials of container technology and its impact on software development.</p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Monolithic vs Microservices Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Comparison between traditional monolithic and modern microservices approaches.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Introduction to Virtualisation and Containerisation</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Basic concepts of virtualisation and how containerisation offers streamlined deployment.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Docker Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key components and structure of Docker's system architecture.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Setting up Docker</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Guidelines for Docker installation and initial setup on various platforms.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">6. Docker Registry, Images, and Containers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>The roles and relationships between Docker Registry, images, and containers.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Running Docker Containers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Fundamentals of managing Docker containers' lifecycle.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Docker Logs and Tags</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Techniques for handling Docker logs and utilizing tags for image management.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Docker Volumes and Networks</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Using Docker volumes for data persistence and networks for inter-container communication.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">10. Dockerize Applications and Docker Compose</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Strategies for containerizing applications and orchestrating with Docker Compose.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 7 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSeven">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                <span class="module-badge me-2">Module 7</span>
                Agile and Scrum
              </button>
            </h2>
            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to CI/CD & Jenkins</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Combined basics of Continuous Integration, Continuous Deployment, and the role of Jenkins.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Benefits and Requirements of CI/CD</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key advantages of adopting CI/CD and the foundational requirements for successful implementation.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Setting Up Jenkins</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Initial setup and configuration of Jenkins for CI/CD pipelines.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Build Tools and Repository Management</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Overview of essential build tools and repository managers integrated with Jenkins for CI.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Jenkins Job Configuration</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Creating and configuring jobs in Jenkins for automated builds.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">6.  Creating and configuring jobs in Jenkins for automated builds.</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Using build triggers in Jenkins for automatic job execution based on specific conditions.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Jenkins Pipelines and Pipeline as Code</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Developing robust CI/CD workflows using Jenkins Pipelines and defining them through code.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Utilizing Jenkins Plugins</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Enhancing Jenkins functionalities with plugins for broader CI/CD capabilities.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Continuous Deployment with Jenkins</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Implementing continuous deployment practices using Jenkins to streamline software releases.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">10. Jenkins Integrations</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Integrating Jenkins with other tools and technologies to create a comprehensive CI/CD ecosystem.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 8 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingEight">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                <span class="module-badge me-2">Module 8</span>
                Code Quality with SonarQube
              </button>
            </h2>
            <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">Introduction to SonarQube</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Purpose and benefits of using SonarQube in software development.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Core Features</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Static Code Analysis: Identifies bugs, vulnerabilities, and code smells. <br>
                        <i class="fa-solid fa-star me-2"></i>Quality Gates: Ensures code meets quality standards. <br>
                        <i class="fa-solid fa-star me-2"></i>Continuous Integration: Integrates with CI/CD pipelines for automated checks. <br>
                        <i class="fa-solid fa-star me-2"></i>Security Analysis: Highlights security vulnerabilities.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Setup and Use</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Installation steps. <br>
                        <i class="fa-solid fa-star me-2"></i>Running initial code analysis and interpreting results.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Implementing Agile with Scrum</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Strategies for applying Agile and Scrum practices in software development projects.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Agile Project Management Best Practices</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Essential practices for leading Agile projects, emphasizing communication and continuous improvement.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 9 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingNine">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                <span class="module-badge me-2">Module 9</span>
                Artifact Storage with Nexus Repository
              </button>
            </h2>
            <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">Introduction to Nexus Repository</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Purpose and advantages of using Nexus Repository in development environments.</p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Key Features</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Artifact Storage: Manages libraries, build artifacts, and binaries. <br>
                        <i class="fa-solid fa-star me-2"></i>Repository Management: Supports multiple repository formats like Maven, NuGet, and Docker. <br>
                        <i class="fa-solid fa-star me-2"></i>Access Control: Manages user permissions for better security.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Installation and Configuration</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Step-by-step guide for setting up Nexus Repository.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Using Nexus Repository</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Uploading and managing artifacts. <br>
                        <i class="fa-solid fa-star me-2"></i>Integrating with build tools and CI/CD pipelines.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Best Practices</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Efficient repository organization and version control. <br>
                        <i class="fa-solid fa-star me-2"></i>Case studies highlighting successful Nexus Repository implementations.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
          <div class="container mt-3">
      <div class="button-container">
        <button class="cta-button w-75 fw-bold">Orchestration - with Kubernetes</button>
      </div>
        <div class="accordion" id="accordionExample">
          <!-- Accordion Item 1 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <span class="module-badge me-2">Module 1</span>
                Applications & Web Technologies
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. What is an Application?</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Overview of applications and their significance.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Types of Applications</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Classification and examples of various application types.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Fundamentals of Web Applications</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Basic concepts and components of web applications.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Web Application Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Structure and design patterns in web application architecture.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Web Technologies used in Projects</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key technologies and frameworks used in web application development.
                      </p>
                    </div>
                  </div>
                  
              </div>
            </div>
          </div>
          <!-- Accordion Item 2 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false" aria-controls="collapseTwo">
                <span class="module-badge me-2">Module 2</span>
                Software Development Life Cycle (SDLC)
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to Software Development Life Cycle</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>The phases, importance, and overview of SDLC.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Application Lifecycle Management - ALM</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Tools, processes, and overview of ALM.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. SDLC Methodologies</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Examination of different methodologies used in software development.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. DevOps Process</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding the principles, practices, and benefits of DevOps.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Web Technologies used in Projects</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key technologies and frameworks used in web application development.
                      </p>
                    </div>
                  </div>
                  
                  
              </div>
            </div>
          </div>
          <!-- Accordion Item 3 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <span class="module-badge me-2">Module 3</span>
                Agile and Scrum
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction To Agile & Scrum</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Fundamental overview of Agile methodologies and the Scrum framework.</p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. The Principles of Agile Methodology</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Core principles of Agile focusing on iterative development and customer collaboration.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Scrum Framework: Roles, Artifacts, and Events</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key components of Scrum, including its roles, artifacts, and structured events.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Implementing Agile with Scrum</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Strategies for applying Agile and Scrum practices in software development projects.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Agile Project Management Best Practices</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Essential practices for leading Agile projects, emphasizing communication and continuous improvement.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
  
  
          <!-- Accordion item 4 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <span class="module-badge me-2">Module 4</span>
                Linux For DevOps & Cloud
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1.  Introduction to Linux OS</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Exploring the fundamentals of the Linux operating system.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Linux Distributions and Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding different distributions and the architecture of Linux.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Command Line Interface (CLI) & Filesystem</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Mastering the CLI and navigating the Linux filesystem.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. File Management and vi Editor</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Managing files and editing them using the vi editor.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Archives and Package Management</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Utilizing tar, zip utilities, and managing packages in Linux.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">5. System Installation and Package Managers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Installing software on Ubuntu, using .deb files, and the APT package manager.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">6. Users, Groups, and Permissions</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Managing users and groups, and configuring permissions.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Networking Basics: IP Address, Protocols, & Ports</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Networking Basics: IP Address, Protocols, & Ports.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Archives and Package Management</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Configuring firewalls and understanding basic security measures.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Load Balancers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Basics of load balancing in a Linux environment for optimizing performance and reliability
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 5 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                <span class="module-badge me-2">Module 5</span>
                Version Control - GIT, GITHUB
              </button>
            </h2>
            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to Version Control System</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Basics of version control and its importance in software development.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Centralised vs Distributed Version Control System</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Differences between centralized and distributed systems, with a focus on their advantages and use cases.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Git & GitHub Introduction</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Overview of Git and GitHub, and how they revolutionize code management and collaboration.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Git Workflow</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding the standard workflow in Git, including stages of code changes and commit practices.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. GitHub for Collaboration</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Using GitHub features for project collaboration, including issues, forks, and pull requests.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">6. Git Branching Model</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Strategies for branching in Git, including feature branches and the master branch.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Git Merging and Pull Requests</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Techniques for merging branches and the role of pull requests in collaborative projects.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Git Rebase</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Understanding rebase, its advantages, and how it differs from merging.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Handling Detached Head and Undoing Changes</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Managing a detached HEAD in Git and various ways to undo changes.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">10. Advanced Git Features: Git Ignore, Tagging</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Utilizing .gitignore for excluding files from tracking, and tagging for marking specific points in history.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 6 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                <span class="module-badge me-2">Module 6</span>
                Containerisation - Docker
              </button>
            </h2>
            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to Containerisation</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Essentials of container technology and its impact on software development.</p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Monolithic vs Microservices Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Comparison between traditional monolithic and modern microservices approaches.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Introduction to Virtualisation and Containerisation</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Basic concepts of virtualisation and how containerisation offers streamlined deployment.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Docker Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key components and structure of Docker's system architecture.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Setting up Docker</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Guidelines for Docker installation and initial setup on various platforms.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">6. Docker Registry, Images, and Containers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>The roles and relationships between Docker Registry, images, and containers.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Running Docker Containers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Fundamentals of managing Docker containers' lifecycle.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Docker Logs and Tags</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Techniques for handling Docker logs and utilizing tags for image management.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Docker Volumes and Networks</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Using Docker volumes for data persistence and networks for inter-container communication.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">10. Dockerize Applications and Docker Compose</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Strategies for containerizing applications and orchestrating with Docker Compose.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 7 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSeven">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                <span class="module-badge me-2">Module 7</span>
                Agile and Scrum
              </button>
            </h2>
            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to CI/CD & Jenkins</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Combined basics of Continuous Integration, Continuous Deployment, and the role of Jenkins.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Benefits and Requirements of CI/CD</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key advantages of adopting CI/CD and the foundational requirements for successful implementation.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Setting Up Jenkins</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Initial setup and configuration of Jenkins for CI/CD pipelines.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Build Tools and Repository Management</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Overview of essential build tools and repository managers integrated with Jenkins for CI.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Jenkins Job Configuration</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Creating and configuring jobs in Jenkins for automated builds.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">6.  Creating and configuring jobs in Jenkins for automated builds.</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Using build triggers in Jenkins for automatic job execution based on specific conditions.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Jenkins Pipelines and Pipeline as Code</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Developing robust CI/CD workflows using Jenkins Pipelines and defining them through code.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Utilizing Jenkins Plugins</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Enhancing Jenkins functionalities with plugins for broader CI/CD capabilities.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Continuous Deployment with Jenkins</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Implementing continuous deployment practices using Jenkins to streamline software releases.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">10. Jenkins Integrations</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Integrating Jenkins with other tools and technologies to create a comprehensive CI/CD ecosystem.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 8 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingEight">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                <span class="module-badge me-2">Module 8</span>
                Code Quality with SonarQube
              </button>
            </h2>
            <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">Introduction to SonarQube</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Purpose and benefits of using SonarQube in software development.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Core Features</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Static Code Analysis: Identifies bugs, vulnerabilities, and code smells. <br>
                        <i class="fa-solid fa-star me-2"></i>Quality Gates: Ensures code meets quality standards. <br>
                        <i class="fa-solid fa-star me-2"></i>Continuous Integration: Integrates with CI/CD pipelines for automated checks. <br>
                        <i class="fa-solid fa-star me-2"></i>Security Analysis: Highlights security vulnerabilities.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Setup and Use</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Installation steps. <br>
                        <i class="fa-solid fa-star me-2"></i>Running initial code analysis and interpreting results.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Implementing Agile with Scrum</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Strategies for applying Agile and Scrum practices in software development projects.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Agile Project Management Best Practices</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Essential practices for leading Agile projects, emphasizing communication and continuous improvement.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 9 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingNine">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                <span class="module-badge me-2">Module 9</span>
                Artifact Storage with Nexus Repository
              </button>
            </h2>
            <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">Introduction to Nexus Repository</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Purpose and advantages of using Nexus Repository in development environments.</p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Key Features</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Artifact Storage: Manages libraries, build artifacts, and binaries. <br>
                        <i class="fa-solid fa-star me-2"></i>Repository Management: Supports multiple repository formats like Maven, NuGet, and Docker. <br>
                        <i class="fa-solid fa-star me-2"></i>Access Control: Manages user permissions for better security.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Installation and Configuration</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Step-by-step guide for setting up Nexus Repository.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Using Nexus Repository</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Uploading and managing artifacts. <br>
                        <i class="fa-solid fa-star me-2"></i>Integrating with build tools and CI/CD pipelines.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Best Practices</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Efficient repository organization and version control. <br>
                        <i class="fa-solid fa-star me-2"></i>Case studies highlighting successful Nexus Repository implementations.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
          <div class="container mt-3">
      <div class="button-container">
        <button class="cta-button w-75 fw-bold">Terraform ( IAAC)</button>
      </div>
        <div class="accordion" id="accordionExample">
          <!-- Accordion Item 1 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <span class="module-badge me-2">Module 1</span>
                Applications & Web Technologies
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. What is an Application?</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Overview of applications and their significance.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Types of Applications</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Classification and examples of various application types.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Fundamentals of Web Applications</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Basic concepts and components of web applications.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Web Application Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Structure and design patterns in web application architecture.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Web Technologies used in Projects</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key technologies and frameworks used in web application development.
                      </p>
                    </div>
                  </div>
                  
              </div>
            </div>
          </div>
          <!-- Accordion Item 2 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false" aria-controls="collapseTwo">
                <span class="module-badge me-2">Module 2</span>
                Software Development Life Cycle (SDLC)
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to Software Development Life Cycle</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>The phases, importance, and overview of SDLC.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Application Lifecycle Management - ALM</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Tools, processes, and overview of ALM.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. SDLC Methodologies</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Examination of different methodologies used in software development.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. DevOps Process</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding the principles, practices, and benefits of DevOps.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Web Technologies used in Projects</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key technologies and frameworks used in web application development.
                      </p>
                    </div>
                  </div>
                  
                  
              </div>
            </div>
          </div>
          <!-- Accordion Item 3 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <span class="module-badge me-2">Module 3</span>
                Agile and Scrum
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction To Agile & Scrum</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Fundamental overview of Agile methodologies and the Scrum framework.</p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. The Principles of Agile Methodology</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Core principles of Agile focusing on iterative development and customer collaboration.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Scrum Framework: Roles, Artifacts, and Events</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key components of Scrum, including its roles, artifacts, and structured events.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Implementing Agile with Scrum</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Strategies for applying Agile and Scrum practices in software development projects.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Agile Project Management Best Practices</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Essential practices for leading Agile projects, emphasizing communication and continuous improvement.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
  
  
          <!-- Accordion item 4 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <span class="module-badge me-2">Module 4</span>
                Linux For DevOps & Cloud
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1.  Introduction to Linux OS</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Exploring the fundamentals of the Linux operating system.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Linux Distributions and Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding different distributions and the architecture of Linux.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Command Line Interface (CLI) & Filesystem</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Mastering the CLI and navigating the Linux filesystem.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. File Management and vi Editor</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Managing files and editing them using the vi editor.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Archives and Package Management</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Utilizing tar, zip utilities, and managing packages in Linux.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">5. System Installation and Package Managers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Installing software on Ubuntu, using .deb files, and the APT package manager.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">6. Users, Groups, and Permissions</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Managing users and groups, and configuring permissions.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Networking Basics: IP Address, Protocols, & Ports</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Networking Basics: IP Address, Protocols, & Ports.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Archives and Package Management</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Configuring firewalls and understanding basic security measures.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Load Balancers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Basics of load balancing in a Linux environment for optimizing performance and reliability
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 5 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                <span class="module-badge me-2">Module 5</span>
                Version Control - GIT, GITHUB
              </button>
            </h2>
            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to Version Control System</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Basics of version control and its importance in software development.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Centralised vs Distributed Version Control System</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Differences between centralized and distributed systems, with a focus on their advantages and use cases.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Git & GitHub Introduction</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Overview of Git and GitHub, and how they revolutionize code management and collaboration.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Git Workflow</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding the standard workflow in Git, including stages of code changes and commit practices.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. GitHub for Collaboration</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Using GitHub features for project collaboration, including issues, forks, and pull requests.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">6. Git Branching Model</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Strategies for branching in Git, including feature branches and the master branch.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Git Merging and Pull Requests</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Techniques for merging branches and the role of pull requests in collaborative projects.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Git Rebase</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Understanding rebase, its advantages, and how it differs from merging.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Handling Detached Head and Undoing Changes</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Managing a detached HEAD in Git and various ways to undo changes.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">10. Advanced Git Features: Git Ignore, Tagging</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Utilizing .gitignore for excluding files from tracking, and tagging for marking specific points in history.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 6 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                <span class="module-badge me-2">Module 6</span>
                Containerisation - Docker
              </button>
            </h2>
            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to Containerisation</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Essentials of container technology and its impact on software development.</p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Monolithic vs Microservices Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Comparison between traditional monolithic and modern microservices approaches.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Introduction to Virtualisation and Containerisation</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Basic concepts of virtualisation and how containerisation offers streamlined deployment.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Docker Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key components and structure of Docker's system architecture.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Setting up Docker</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Guidelines for Docker installation and initial setup on various platforms.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">6. Docker Registry, Images, and Containers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>The roles and relationships between Docker Registry, images, and containers.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Running Docker Containers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Fundamentals of managing Docker containers' lifecycle.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Docker Logs and Tags</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Techniques for handling Docker logs and utilizing tags for image management.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Docker Volumes and Networks</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Using Docker volumes for data persistence and networks for inter-container communication.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">10. Dockerize Applications and Docker Compose</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Strategies for containerizing applications and orchestrating with Docker Compose.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 7 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSeven">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                <span class="module-badge me-2">Module 7</span>
                Agile and Scrum
              </button>
            </h2>
            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to CI/CD & Jenkins</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Combined basics of Continuous Integration, Continuous Deployment, and the role of Jenkins.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Benefits and Requirements of CI/CD</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key advantages of adopting CI/CD and the foundational requirements for successful implementation.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Setting Up Jenkins</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Initial setup and configuration of Jenkins for CI/CD pipelines.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Build Tools and Repository Management</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Overview of essential build tools and repository managers integrated with Jenkins for CI.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Jenkins Job Configuration</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Creating and configuring jobs in Jenkins for automated builds.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">6.  Creating and configuring jobs in Jenkins for automated builds.</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Using build triggers in Jenkins for automatic job execution based on specific conditions.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Jenkins Pipelines and Pipeline as Code</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Developing robust CI/CD workflows using Jenkins Pipelines and defining them through code.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Utilizing Jenkins Plugins</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Enhancing Jenkins functionalities with plugins for broader CI/CD capabilities.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Continuous Deployment with Jenkins</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Implementing continuous deployment practices using Jenkins to streamline software releases.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">10. Jenkins Integrations</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Integrating Jenkins with other tools and technologies to create a comprehensive CI/CD ecosystem.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 8 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingEight">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                <span class="module-badge me-2">Module 8</span>
                Code Quality with SonarQube
              </button>
            </h2>
            <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">Introduction to SonarQube</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Purpose and benefits of using SonarQube in software development.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Core Features</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Static Code Analysis: Identifies bugs, vulnerabilities, and code smells. <br>
                        <i class="fa-solid fa-star me-2"></i>Quality Gates: Ensures code meets quality standards. <br>
                        <i class="fa-solid fa-star me-2"></i>Continuous Integration: Integrates with CI/CD pipelines for automated checks. <br>
                        <i class="fa-solid fa-star me-2"></i>Security Analysis: Highlights security vulnerabilities.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Setup and Use</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Installation steps. <br>
                        <i class="fa-solid fa-star me-2"></i>Running initial code analysis and interpreting results.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Implementing Agile with Scrum</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Strategies for applying Agile and Scrum practices in software development projects.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Agile Project Management Best Practices</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Essential practices for leading Agile projects, emphasizing communication and continuous improvement.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 9 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingNine">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                <span class="module-badge me-2">Module 9</span>
                Artifact Storage with Nexus Repository
              </button>
            </h2>
            <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">Introduction to Nexus Repository</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Purpose and advantages of using Nexus Repository in development environments.</p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Key Features</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Artifact Storage: Manages libraries, build artifacts, and binaries. <br>
                        <i class="fa-solid fa-star me-2"></i>Repository Management: Supports multiple repository formats like Maven, NuGet, and Docker. <br>
                        <i class="fa-solid fa-star me-2"></i>Access Control: Manages user permissions for better security.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Installation and Configuration</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Step-by-step guide for setting up Nexus Repository.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Using Nexus Repository</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Uploading and managing artifacts. <br>
                        <i class="fa-solid fa-star me-2"></i>Integrating with build tools and CI/CD pipelines.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Best Practices</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Efficient repository organization and version control. <br>
                        <i class="fa-solid fa-star me-2"></i>Case studies highlighting successful Nexus Repository implementations.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
          <div class="container mt-3">
      <div class="button-container">
        <button class="cta-button w-75 fw-bold">Azure DevOps (Application Lifecycle Management)</button>
      </div>
        <div class="accordion" id="accordionExample">
          <!-- Accordion Item 1 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <span class="module-badge me-2">Module 1</span>
                Applications & Web Technologies
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. What is an Application?</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Overview of applications and their significance.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Types of Applications</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Classification and examples of various application types.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Fundamentals of Web Applications</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Basic concepts and components of web applications.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Web Application Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Structure and design patterns in web application architecture.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Web Technologies used in Projects</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key technologies and frameworks used in web application development.
                      </p>
                    </div>
                  </div>
                  
              </div>
            </div>
          </div>
          <!-- Accordion Item 2 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false" aria-controls="collapseTwo">
                <span class="module-badge me-2">Module 2</span>
                Software Development Life Cycle (SDLC)
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to Software Development Life Cycle</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>The phases, importance, and overview of SDLC.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Application Lifecycle Management - ALM</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Tools, processes, and overview of ALM.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. SDLC Methodologies</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Examination of different methodologies used in software development.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. DevOps Process</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding the principles, practices, and benefits of DevOps.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Web Technologies used in Projects</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key technologies and frameworks used in web application development.
                      </p>
                    </div>
                  </div>
                  
                  
              </div>
            </div>
          </div>
          <!-- Accordion Item 3 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <span class="module-badge me-2">Module 3</span>
                Agile and Scrum
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction To Agile & Scrum</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Fundamental overview of Agile methodologies and the Scrum framework.</p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. The Principles of Agile Methodology</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Core principles of Agile focusing on iterative development and customer collaboration.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Scrum Framework: Roles, Artifacts, and Events</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key components of Scrum, including its roles, artifacts, and structured events.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Implementing Agile with Scrum</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Strategies for applying Agile and Scrum practices in software development projects.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Agile Project Management Best Practices</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Essential practices for leading Agile projects, emphasizing communication and continuous improvement.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
  
  
          <!-- Accordion item 4 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <span class="module-badge me-2">Module 4</span>
                Linux For DevOps & Cloud
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1.  Introduction to Linux OS</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Exploring the fundamentals of the Linux operating system.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Linux Distributions and Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding different distributions and the architecture of Linux.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Command Line Interface (CLI) & Filesystem</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Mastering the CLI and navigating the Linux filesystem.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. File Management and vi Editor</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Managing files and editing them using the vi editor.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Archives and Package Management</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Utilizing tar, zip utilities, and managing packages in Linux.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">5. System Installation and Package Managers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Installing software on Ubuntu, using .deb files, and the APT package manager.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">6. Users, Groups, and Permissions</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Managing users and groups, and configuring permissions.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Networking Basics: IP Address, Protocols, & Ports</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Networking Basics: IP Address, Protocols, & Ports.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Archives and Package Management</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Configuring firewalls and understanding basic security measures.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Load Balancers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Basics of load balancing in a Linux environment for optimizing performance and reliability
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 5 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                <span class="module-badge me-2">Module 5</span>
                Version Control - GIT, GITHUB
              </button>
            </h2>
            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to Version Control System</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Basics of version control and its importance in software development.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Centralised vs Distributed Version Control System</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Differences between centralized and distributed systems, with a focus on their advantages and use cases.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Git & GitHub Introduction</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Overview of Git and GitHub, and how they revolutionize code management and collaboration.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Git Workflow</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding the standard workflow in Git, including stages of code changes and commit practices.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. GitHub for Collaboration</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Using GitHub features for project collaboration, including issues, forks, and pull requests.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">6. Git Branching Model</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Strategies for branching in Git, including feature branches and the master branch.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Git Merging and Pull Requests</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Techniques for merging branches and the role of pull requests in collaborative projects.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Git Rebase</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Understanding rebase, its advantages, and how it differs from merging.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Handling Detached Head and Undoing Changes</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Managing a detached HEAD in Git and various ways to undo changes.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">10. Advanced Git Features: Git Ignore, Tagging</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Utilizing .gitignore for excluding files from tracking, and tagging for marking specific points in history.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 6 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                <span class="module-badge me-2">Module 6</span>
                Containerisation - Docker
              </button>
            </h2>
            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to Containerisation</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Essentials of container technology and its impact on software development.</p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Monolithic vs Microservices Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Comparison between traditional monolithic and modern microservices approaches.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Introduction to Virtualisation and Containerisation</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Basic concepts of virtualisation and how containerisation offers streamlined deployment.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Docker Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key components and structure of Docker's system architecture.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Setting up Docker</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Guidelines for Docker installation and initial setup on various platforms.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">6. Docker Registry, Images, and Containers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>The roles and relationships between Docker Registry, images, and containers.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Running Docker Containers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Fundamentals of managing Docker containers' lifecycle.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Docker Logs and Tags</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Techniques for handling Docker logs and utilizing tags for image management.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Docker Volumes and Networks</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Using Docker volumes for data persistence and networks for inter-container communication.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">10. Dockerize Applications and Docker Compose</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Strategies for containerizing applications and orchestrating with Docker Compose.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 7 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSeven">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                <span class="module-badge me-2">Module 7</span>
                Agile and Scrum
              </button>
            </h2>
            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to CI/CD & Jenkins</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Combined basics of Continuous Integration, Continuous Deployment, and the role of Jenkins.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Benefits and Requirements of CI/CD</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key advantages of adopting CI/CD and the foundational requirements for successful implementation.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Setting Up Jenkins</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Initial setup and configuration of Jenkins for CI/CD pipelines.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Build Tools and Repository Management</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Overview of essential build tools and repository managers integrated with Jenkins for CI.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Jenkins Job Configuration</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Creating and configuring jobs in Jenkins for automated builds.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">6.  Creating and configuring jobs in Jenkins for automated builds.</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Using build triggers in Jenkins for automatic job execution based on specific conditions.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Jenkins Pipelines and Pipeline as Code</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Developing robust CI/CD workflows using Jenkins Pipelines and defining them through code.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Utilizing Jenkins Plugins</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Enhancing Jenkins functionalities with plugins for broader CI/CD capabilities.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Continuous Deployment with Jenkins</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Implementing continuous deployment practices using Jenkins to streamline software releases.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">10. Jenkins Integrations</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Integrating Jenkins with other tools and technologies to create a comprehensive CI/CD ecosystem.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 8 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingEight">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                <span class="module-badge me-2">Module 8</span>
                Code Quality with SonarQube
              </button>
            </h2>
            <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">Introduction to SonarQube</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Purpose and benefits of using SonarQube in software development.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Core Features</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Static Code Analysis: Identifies bugs, vulnerabilities, and code smells. <br>
                        <i class="fa-solid fa-star me-2"></i>Quality Gates: Ensures code meets quality standards. <br>
                        <i class="fa-solid fa-star me-2"></i>Continuous Integration: Integrates with CI/CD pipelines for automated checks. <br>
                        <i class="fa-solid fa-star me-2"></i>Security Analysis: Highlights security vulnerabilities.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Setup and Use</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Installation steps. <br>
                        <i class="fa-solid fa-star me-2"></i>Running initial code analysis and interpreting results.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Implementing Agile with Scrum</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Strategies for applying Agile and Scrum practices in software development projects.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Agile Project Management Best Practices</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Essential practices for leading Agile projects, emphasizing communication and continuous improvement.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 9 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingNine">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                <span class="module-badge me-2">Module 9</span>
                Artifact Storage with Nexus Repository
              </button>
            </h2>
            <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">Introduction to Nexus Repository</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Purpose and advantages of using Nexus Repository in development environments.</p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Key Features</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Artifact Storage: Manages libraries, build artifacts, and binaries. <br>
                        <i class="fa-solid fa-star me-2"></i>Repository Management: Supports multiple repository formats like Maven, NuGet, and Docker. <br>
                        <i class="fa-solid fa-star me-2"></i>Access Control: Manages user permissions for better security.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Installation and Configuration</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Step-by-step guide for setting up Nexus Repository.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Using Nexus Repository</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Uploading and managing artifacts. <br>
                        <i class="fa-solid fa-star me-2"></i>Integrating with build tools and CI/CD pipelines.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Best Practices</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Efficient repository organization and version control. <br>
                        <i class="fa-solid fa-star me-2"></i>Case studies highlighting successful Nexus Repository implementations.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
          <div class="container mt-3">
      <div class="button-container">
        <button class="cta-button w-75 fw-bold">Automation with Python</button>
      </div>
        <div class="accordion" id="accordionExample">
          <!-- Accordion Item 1 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <span class="module-badge me-2">Module 1</span>
                Applications & Web Technologies
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. What is an Application?</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Overview of applications and their significance.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Types of Applications</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Classification and examples of various application types.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Fundamentals of Web Applications</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Basic concepts and components of web applications.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Web Application Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Structure and design patterns in web application architecture.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Web Technologies used in Projects</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key technologies and frameworks used in web application development.
                      </p>
                    </div>
                  </div>
                  
              </div>
            </div>
          </div>
          <!-- Accordion Item 2 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false" aria-controls="collapseTwo">
                <span class="module-badge me-2">Module 2</span>
                Software Development Life Cycle (SDLC)
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to Software Development Life Cycle</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>The phases, importance, and overview of SDLC.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Application Lifecycle Management - ALM</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Tools, processes, and overview of ALM.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. SDLC Methodologies</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Examination of different methodologies used in software development.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. DevOps Process</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding the principles, practices, and benefits of DevOps.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Web Technologies used in Projects</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key technologies and frameworks used in web application development.
                      </p>
                    </div>
                  </div>
                  
                  
              </div>
            </div>
          </div>
          <!-- Accordion Item 3 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <span class="module-badge me-2">Module 3</span>
                Agile and Scrum
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction To Agile & Scrum</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Fundamental overview of Agile methodologies and the Scrum framework.</p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. The Principles of Agile Methodology</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Core principles of Agile focusing on iterative development and customer collaboration.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Scrum Framework: Roles, Artifacts, and Events</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key components of Scrum, including its roles, artifacts, and structured events.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Implementing Agile with Scrum</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Strategies for applying Agile and Scrum practices in software development projects.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Agile Project Management Best Practices</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Essential practices for leading Agile projects, emphasizing communication and continuous improvement.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
  
  
          <!-- Accordion item 4 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <span class="module-badge me-2">Module 4</span>
                Linux For DevOps & Cloud
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1.  Introduction to Linux OS</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Exploring the fundamentals of the Linux operating system.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Linux Distributions and Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding different distributions and the architecture of Linux.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Command Line Interface (CLI) & Filesystem</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Mastering the CLI and navigating the Linux filesystem.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. File Management and vi Editor</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Managing files and editing them using the vi editor.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Archives and Package Management</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Utilizing tar, zip utilities, and managing packages in Linux.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">5. System Installation and Package Managers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Installing software on Ubuntu, using .deb files, and the APT package manager.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">6. Users, Groups, and Permissions</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Managing users and groups, and configuring permissions.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Networking Basics: IP Address, Protocols, & Ports</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Networking Basics: IP Address, Protocols, & Ports.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Archives and Package Management</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Configuring firewalls and understanding basic security measures.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Load Balancers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Basics of load balancing in a Linux environment for optimizing performance and reliability
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 5 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                <span class="module-badge me-2">Module 5</span>
                Version Control - GIT, GITHUB
              </button>
            </h2>
            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to Version Control System</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Basics of version control and its importance in software development.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Centralised vs Distributed Version Control System</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Differences between centralized and distributed systems, with a focus on their advantages and use cases.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Git & GitHub Introduction</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Overview of Git and GitHub, and how they revolutionize code management and collaboration.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Git Workflow</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding the standard workflow in Git, including stages of code changes and commit practices.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. GitHub for Collaboration</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Using GitHub features for project collaboration, including issues, forks, and pull requests.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">6. Git Branching Model</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Strategies for branching in Git, including feature branches and the master branch.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Git Merging and Pull Requests</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Techniques for merging branches and the role of pull requests in collaborative projects.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Git Rebase</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Understanding rebase, its advantages, and how it differs from merging.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Handling Detached Head and Undoing Changes</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Managing a detached HEAD in Git and various ways to undo changes.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">10. Advanced Git Features: Git Ignore, Tagging</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Utilizing .gitignore for excluding files from tracking, and tagging for marking specific points in history.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 6 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                <span class="module-badge me-2">Module 6</span>
                Containerisation - Docker
              </button>
            </h2>
            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to Containerisation</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Essentials of container technology and its impact on software development.</p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Monolithic vs Microservices Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Comparison between traditional monolithic and modern microservices approaches.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Introduction to Virtualisation and Containerisation</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Basic concepts of virtualisation and how containerisation offers streamlined deployment.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Docker Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key components and structure of Docker's system architecture.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Setting up Docker</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Guidelines for Docker installation and initial setup on various platforms.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">6. Docker Registry, Images, and Containers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>The roles and relationships between Docker Registry, images, and containers.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Running Docker Containers</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Fundamentals of managing Docker containers' lifecycle.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Docker Logs and Tags</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Techniques for handling Docker logs and utilizing tags for image management.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Docker Volumes and Networks</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Using Docker volumes for data persistence and networks for inter-container communication.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">10. Dockerize Applications and Docker Compose</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Strategies for containerizing applications and orchestrating with Docker Compose.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 7 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSeven">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                <span class="module-badge me-2">Module 7</span>
                Agile and Scrum
              </button>
            </h2>
            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to CI/CD & Jenkins</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Combined basics of Continuous Integration, Continuous Deployment, and the role of Jenkins.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Benefits and Requirements of CI/CD</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Key advantages of adopting CI/CD and the foundational requirements for successful implementation.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Setting Up Jenkins</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Initial setup and configuration of Jenkins for CI/CD pipelines.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Build Tools and Repository Management</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Overview of essential build tools and repository managers integrated with Jenkins for CI.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Jenkins Job Configuration</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Creating and configuring jobs in Jenkins for automated builds.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">6.  Creating and configuring jobs in Jenkins for automated builds.</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Using build triggers in Jenkins for automatic job execution based on specific conditions.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Jenkins Pipelines and Pipeline as Code</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Developing robust CI/CD workflows using Jenkins Pipelines and defining them through code.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Utilizing Jenkins Plugins</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Enhancing Jenkins functionalities with plugins for broader CI/CD capabilities.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Continuous Deployment with Jenkins</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Implementing continuous deployment practices using Jenkins to streamline software releases.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">10. Jenkins Integrations</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Integrating Jenkins with other tools and technologies to create a comprehensive CI/CD ecosystem.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 8 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingEight">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                <span class="module-badge me-2">Module 8</span>
                Code Quality with SonarQube
              </button>
            </h2>
            <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">Introduction to SonarQube</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Purpose and benefits of using SonarQube in software development.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Core Features</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Static Code Analysis: Identifies bugs, vulnerabilities, and code smells. <br>
                        <i class="fa-solid fa-star me-2"></i>Quality Gates: Ensures code meets quality standards. <br>
                        <i class="fa-solid fa-star me-2"></i>Continuous Integration: Integrates with CI/CD pipelines for automated checks. <br>
                        <i class="fa-solid fa-star me-2"></i>Security Analysis: Highlights security vulnerabilities.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Setup and Use</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Installation steps. <br>
                        <i class="fa-solid fa-star me-2"></i>Running initial code analysis and interpreting results.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Implementing Agile with Scrum</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Strategies for applying Agile and Scrum practices in software development projects.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Agile Project Management Best Practices</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Essential practices for leading Agile projects, emphasizing communication and continuous improvement.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 9 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingNine">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                <span class="module-badge me-2">Module 9</span>
                Artifact Storage with Nexus Repository
              </button>
            </h2>
            <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">Introduction to Nexus Repository</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Purpose and advantages of using Nexus Repository in development environments.</p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Key Features</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Artifact Storage: Manages libraries, build artifacts, and binaries. <br>
                        <i class="fa-solid fa-star me-2"></i>Repository Management: Supports multiple repository formats like Maven, NuGet, and Docker. <br>
                        <i class="fa-solid fa-star me-2"></i>Access Control: Manages user permissions for better security.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Installation and Configuration</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Step-by-step guide for setting up Nexus Repository.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Using Nexus Repository</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Uploading and managing artifacts. <br>
                        <i class="fa-solid fa-star me-2"></i>Integrating with build tools and CI/CD pipelines.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">Best Practices</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Efficient repository organization and version control. <br>
                        <i class="fa-solid fa-star me-2"></i>Case studies highlighting successful Nexus Repository implementations.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
          <div class="container mt-3">
      <div class="button-container">
        <button class="cta-button w-75 fw-bold">Site Reliability Engineer - SRE</button>
      </div>
        <div class="accordion" id="accordionExample">
          <!-- Accordion Item 1 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <span class="module-badge me-2">Module 1</span>
                Introduction to SRE Funamentals
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to SRE</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Defining Site Reliability Engineering and its objectives in maintaining highly reliable and scalable systems.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Introduction to Monitoring</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Exploring the purpose and techniques of monitoring in SRE practices.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3.  Introduction to Observability</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding observability and its difference from and relationship with monitoring.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. SRE Roles and Responsibilities</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Overview of the typical roles, responsibilities, and expectations of an SRE.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. SRE Best Practices and Principles</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Essential practices and foundational principles for effective site reliability engineering.
                      </p>
                    </div>
                  </div>
                  
              </div>
            </div>
          </div>
          <!-- Accordion Item 2 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false" aria-controls="collapseTwo">
                <span class="module-badge me-2">Module 2</span>
                Monitoring with Prometheus
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to Prometheus</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Basics of Prometheus and its role in the monitoring landscape.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Prometheus Architecture</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding the components and architecture of Prometheus.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Monitoring with Prometheus</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Setting up Prometheus for monitoring infrastructure and application metrics.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Scraping Metrics with Prometheus</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Techniques for scraping and collecting metrics from various targets.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Prometheus YAML Configs and Node Exporter</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Configuring Prometheus and using Node Exporter to gather system metrics.
                      </p>
                    </div>
                  </div>
                  
                  
              </div>
            </div>
          </div>
          <!-- Accordion Item 3 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <span class="module-badge me-2">Module 3</span>
                Observability with Grafana
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1.Introduction to Visualization with Grafana</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding the importance of data visualization in observability.</p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Installing Grafana on a Linux Server</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Step-by-step installation of Grafana for setting up monitoring dashboards.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Grafana User Interface Overview</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Navigating through Grafana's UI and understanding its features.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Creating Grafana Dashboards</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Techniques for creating insightful and interactive dashboards in Grafana.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Grafana with Docker</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Deploying Grafana within Docker containers for flexible and scalable monitoring solutions.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
  
  
          <!-- Accordion item 4 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <span class="module-badge me-2">Module 4</span>
                Advanced Monitoring and Observability
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1.  Integrating Prometheus and Grafana</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Techniques for integrating Prometheus with Grafana to visualize metrics.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Alerting with Prometheus</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Setting up alert rules in Prometheus and integrating with notification platforms.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3.Log Management and Analysis</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Introduction to log management solutions and integrating them with monitoring tools for full observability.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Tracing and Distributed Tracing</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Managing files and editing them using the vi editor.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. Archives and Package Management</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding tracing and distributed tracing for in-depth insights into application performance.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">5. Cloud Monitoring Solutions</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>verview of cloud-native monitoring and observability solutions provided by cloud service providers.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
          <!-- Accordion Item 5 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                <span class="module-badge me-2">Module 5</span>
                SRE Tools and Automation
              </button>
            </h2>
            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="container mt-3">
                    <h5 class="fw-bold">Topics:</h5>
                    <div class="topic">
                      <p class="fw-bold h6">1. Introduction to Version Control System</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Basics of version control and its importance in software development.
                    </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">2. Centralised vs Distributed Version Control System</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Differences between centralized and distributed systems, with a focus on their advantages and use cases.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">3. Git & GitHub Introduction</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Overview of Git and GitHub, and how they revolutionize code management and collaboration.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">4. Git Workflow</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Understanding the standard workflow in Git, including stages of code changes and commit practices.
                      </p>
                    </div>
                    <div class="topic">
                      <p class="fw-bold h6">5. GitHub for Collaboration</p>
                      <p class="h6 ms-3">
                        <i class="fa-solid fa-star me-2"></i>Using GitHub features for project collaboration, including issues, forks, and pull requests.
                      </p>
                    </div>
                    <div class="topic">
                        <p class="fw-bold h6">6. Git Branching Model</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Strategies for branching in Git, including feature branches and the master branch.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">7. Git Merging and Pull Requests</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Techniques for merging branches and the role of pull requests in collaborative projects.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">8. Git Rebase</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Understanding rebase, its advantages, and how it differs from merging.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">9. Handling Detached Head and Undoing Changes</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Managing a detached HEAD in Git and various ways to undo changes.
                        </p>
                      </div>
                      <div class="topic">
                        <p class="fw-bold h6">10. Advanced Git Features: Git Ignore, Tagging</p>
                        <p class="h6 ms-3">
                          <i class="fa-solid fa-star me-2"></i>Utilizing .gitignore for excluding files from tracking, and tagging for marking specific points in history.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
  
      
        </div>
      </div>
  
  
    </div>
  
  <div class="row platforms">
    <div class="row mt-5">
        <span class="platforms">AND PLATFORMS</span>
        <div class="row" style="justify-content: center;">
            <span style="text-align: center;width: 70px;
                height: 4px;
                flex-shrink: 0; background: linear-gradient(90deg, #224A79 0%, #EFA1A8 100%); z-index: 2;"></span>
        </div>
        <span class="tools">TOOLS</span>
    </div>
  
    <div class="container mt-5">
        <div class="row justify-content-center">
            <div class="col-md-2 col-6 text-center">
                <img src=".././../assets/img/image 36.png" style="max-width: 100%;">
            </div>
            <div class="col-md-2 col-6 text-center">
                <img src="../../assets/img/image 39.png" style="max-width: 100%;">
            </div>
            <div class="col-md-2 col-6 text-center">
                <img src=".././../assets/img/image 32.png" style="max-width: 100%;">
            </div>
            <div class="col-md-2 col-6 text-center">
                <img src=".././../assets/img/image 40.png" style="max-width: 100%;">
            </div>
        </div>
        <div class="row mt-3 justify-content-center">
            <div class="col-md-2 col-6 text-center">
                <img src=".././../assets/img/image 31.png" style="max-width: 100%;">
            </div>
            <div class="col-md-2 col-6 text-center">
                <img src="../../assets/img/image 34.png" style="max-width: 100%;">
            </div>
            <div class="col-md-2 col-6 text-center">
                <img src=".././../assets/img/image 44.png" style="max-width: 100%;">
            </div>
        </div>
    </div>
    
  
  </div>
  
  
  
  
  <div class="row proj"  style="background: #F5F5F5; height: 100%; flex-shrink: 0;">
  
    <div class="row mt-5">
        <span style="text-align: center; font-size: 25px; font-weight: bold;">Python Real Time Projects</span>
        <div class="row" style="justify-content: center;">
            <span style="text-align: center;width: 70px;
                    height: 4px;
                    flex-shrink: 0; background: linear-gradient(90deg, #224A79 0%, #EFA1A8 100%) "></span>
        </div>
    
    </div>
    <div class="p-5">
        <div class="row mt-3 p-5">
            <span class="p-5" style="width:100%;background-color:white">
                <span class="p-3" style="width:50%;">
                    <div class="row">
                        <div class="col-md-5"></div>
                        <div class="col-md-7">
                            <div class="row-mt-1" id="project"> project-1 </div>
                    <div class="row-mt-1 mb-2"><b>Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida </b></div>
                    Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                    ullamcorper amet. Placerat aliquam
                    eleifend proin iaculis sapien
                    tincidunt nascetur. Nulla tempus eu purus in turpis.Placerat aliquam eleifend proin iaculis sapien
                    tincidunt nascetur.
                    Nulla tempus eu purus in turpis.
                        </div>
                        
                    </div>
                    
                </span>
            </span>
  
        </div>
    
        <div class="row  p-5">
            <span class="p-5" style="width:100%;background-color:white">
                <span class="p-3" style="width:50%;">
                    <div class="row">
                        
                        <div class="col-md-7">
                            <div class="row-mt-1" id="project"> project-2 </div>
                    <div class="row-mt-1 mb-2"><b>Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida </b></div>
                    Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                    ullamcorper amet. Placerat aliquam
                    eleifend proin iaculis sapien
                    tincidunt nascetur. Nulla tempus eu purus in turpis.Placerat aliquam eleifend proin iaculis sapien
                    tincidunt nascetur.
                    Nulla tempus eu purus in turpis.
                        </div>
                        <div class="col-md-5"></div>
                        
                    </div>
                    
                </span>
            </span>
  
        </div>
    
        <div class="row  p-5">
            <span class="p-5" style="width:100%;background-color:white">
                <span class="p-3" style="width:50%;">
                    <div class="row">
                        <div class="col-md-5"></div>
                        <div class="col-md-7">
                            <div class="row-mt-1" id="project"> project-3 </div>
                    <div class="row-mt-1 mb-2"><b>Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida </b></div>
                    Lorem ipsum dolor sit amet consectetur. Urna diam turpis gravida
                    ullamcorper amet. Placerat aliquam
                    eleifend proin iaculis sapien
                    tincidunt nascetur. Nulla tempus eu purus in turpis.Placerat aliquam eleifend proin iaculis sapien
                    tincidunt nascetur.
                    Nulla tempus eu purus in turpis.
                        </div>
                        
                    </div>
                    
                </span>
            </span>
  
        </div>
    </div>
  
    
  </div>
  
  <div class="row mt-5  batchschedual">
    <div style="text-align: center;" class="fw-bold">Python Upcoming Batch Schedule</div>
    <div class="row" style="justify-content: center;">
        <span style="text-align: center;width: 70px;
                height: 4px;
                flex-shrink: 0; background: linear-gradient(90deg, #224A79 0%, #EFA1A8 100%) " class="mt-2"></span>
    </div>
  
  </div>
  <div class="container mt-5">
    <div class="row justify-content-center">
        <div class="col p-2">
            <div class="card" style="width:100%;">
                <!-- ... Content for Card 1 ... -->
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"><b>Week Day Batches </b> <br>(mon-fri) </li>
                    <li class="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M8 14C7.71667 14 7.479 13.904 7.287 13.712C7.09567 13.5207 7 13.2833 7 13C7 12.7167 7.09567 12.479 7.287 12.287C7.479 12.0957 7.71667 12 8 12C8.28333 12 8.521 12.0957 8.713 12.287C8.90433 12.479 9 12.7167 9 13C9 13.2833 8.90433 13.5207 8.713 13.712C8.521 13.904 8.28333 14 8 14ZM12 14C11.7167 14 11.4793 13.904 11.288 13.712C11.096 13.5207 11 13.2833 11 13C11 12.7167 11.096 12.479 11.288 12.287C11.4793 12.0957 11.7167 12 12 12C12.2833 12 12.521 12.0957 12.713 12.287C12.9043 12.479 13 12.7167 13 13C13 13.2833 12.9043 13.5207 12.713 13.712C12.521 13.904 12.2833 14 12 14ZM16 14C15.7167 14 15.4793 13.904 15.288 13.712C15.096 13.5207 15 13.2833 15 13C15 12.7167 15.096 12.479 15.288 12.287C15.4793 12.0957 15.7167 12 16 12C16.2833 12 16.5207 12.0957 16.712 12.287C16.904 12.479 17 12.7167 17 13C17 13.2833 16.904 13.5207 16.712 13.712C16.5207 13.904 16.2833 14 16 14ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2.975C6 2.69167 6.09567 2.45833 6.287 2.275C6.479 2.09167 6.71667 2 7 2C7.28333 2 7.521 2.09567 7.713 2.287C7.90433 2.479 8 2.71667 8 3V4H16V2.975C16 2.69167 16.096 2.45833 16.288 2.275C16.4793 2.09167 16.7167 2 17 2C17.2833 2 17.5207 2.09567 17.712 2.287C17.904 2.479 18 2.71667 18 3V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V20C21 20.55 20.8043 21.021 20.413 21.413C20.021 21.8043 19.55 22 19 22H5ZM5 20H19V10H5V20Z" fill="#262626"/>
                    </svg> <b>16-Jan-2023</b><br>Monday</li>
                    <li class="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM17 13H11V7H12.5V11.5H17V13Z" fill="#262626"/>
                    </svg><b>8 AM (IST)</b> <br>1hr-1:30hr/per session</li>
                    <li class="list-group-item"><button class="btn-shed" data-toggle="modal" data-target="#exampleModal"> Our courses</button></li>
                  </ul>
            </div>
        </div>
        <div class="col p-2">
            <div class="card" style="width: 100%;">
                <!-- ... Content for Card 2 ... -->
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"><b>Week Day Batches </b> <br>(mon-fri) </li>
                    <li class="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M8 14C7.71667 14 7.479 13.904 7.287 13.712C7.09567 13.5207 7 13.2833 7 13C7 12.7167 7.09567 12.479 7.287 12.287C7.479 12.0957 7.71667 12 8 12C8.28333 12 8.521 12.0957 8.713 12.287C8.90433 12.479 9 12.7167 9 13C9 13.2833 8.90433 13.5207 8.713 13.712C8.521 13.904 8.28333 14 8 14ZM12 14C11.7167 14 11.4793 13.904 11.288 13.712C11.096 13.5207 11 13.2833 11 13C11 12.7167 11.096 12.479 11.288 12.287C11.4793 12.0957 11.7167 12 12 12C12.2833 12 12.521 12.0957 12.713 12.287C12.9043 12.479 13 12.7167 13 13C13 13.2833 12.9043 13.5207 12.713 13.712C12.521 13.904 12.2833 14 12 14ZM16 14C15.7167 14 15.4793 13.904 15.288 13.712C15.096 13.5207 15 13.2833 15 13C15 12.7167 15.096 12.479 15.288 12.287C15.4793 12.0957 15.7167 12 16 12C16.2833 12 16.5207 12.0957 16.712 12.287C16.904 12.479 17 12.7167 17 13C17 13.2833 16.904 13.5207 16.712 13.712C16.5207 13.904 16.2833 14 16 14ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2.975C6 2.69167 6.09567 2.45833 6.287 2.275C6.479 2.09167 6.71667 2 7 2C7.28333 2 7.521 2.09567 7.713 2.287C7.90433 2.479 8 2.71667 8 3V4H16V2.975C16 2.69167 16.096 2.45833 16.288 2.275C16.4793 2.09167 16.7167 2 17 2C17.2833 2 17.5207 2.09567 17.712 2.287C17.904 2.479 18 2.71667 18 3V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V20C21 20.55 20.8043 21.021 20.413 21.413C20.021 21.8043 19.55 22 19 22H5ZM5 20H19V10H5V20Z" fill="#262626"/>
                    </svg> <b>16-Jan-2023</b><br>Monday</li>
                    <li class="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM17 13H11V7H12.5V11.5H17V13Z" fill="#262626"/>
                    </svg><b>8 AM (IST)</b> <br>1hr-1:30hr/per session</li>
                    <li class="list-group-item"><button class="btn-shed" data-toggle="modal" data-target="#exampleModal"> Our courses</button></li>
                  </ul>
            </div>
        </div>
        <div class="col p-2">
            <div class="card" style="width: 100%;">
                <!-- ... Content for Card 3 ... -->
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"><b>Week Day Batches </b> <br>(mon-fri) </li>
                    <li class="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M8 14C7.71667 14 7.479 13.904 7.287 13.712C7.09567 13.5207 7 13.2833 7 13C7 12.7167 7.09567 12.479 7.287 12.287C7.479 12.0957 7.71667 12 8 12C8.28333 12 8.521 12.0957 8.713 12.287C8.90433 12.479 9 12.7167 9 13C9 13.2833 8.90433 13.5207 8.713 13.712C8.521 13.904 8.28333 14 8 14ZM12 14C11.7167 14 11.4793 13.904 11.288 13.712C11.096 13.5207 11 13.2833 11 13C11 12.7167 11.096 12.479 11.288 12.287C11.4793 12.0957 11.7167 12 12 12C12.2833 12 12.521 12.0957 12.713 12.287C12.9043 12.479 13 12.7167 13 13C13 13.2833 12.9043 13.5207 12.713 13.712C12.521 13.904 12.2833 14 12 14ZM16 14C15.7167 14 15.4793 13.904 15.288 13.712C15.096 13.5207 15 13.2833 15 13C15 12.7167 15.096 12.479 15.288 12.287C15.4793 12.0957 15.7167 12 16 12C16.2833 12 16.5207 12.0957 16.712 12.287C16.904 12.479 17 12.7167 17 13C17 13.2833 16.904 13.5207 16.712 13.712C16.5207 13.904 16.2833 14 16 14ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2.975C6 2.69167 6.09567 2.45833 6.287 2.275C6.479 2.09167 6.71667 2 7 2C7.28333 2 7.521 2.09567 7.713 2.287C7.90433 2.479 8 2.71667 8 3V4H16V2.975C16 2.69167 16.096 2.45833 16.288 2.275C16.4793 2.09167 16.7167 2 17 2C17.2833 2 17.5207 2.09567 17.712 2.287C17.904 2.479 18 2.71667 18 3V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V20C21 20.55 20.8043 21.021 20.413 21.413C20.021 21.8043 19.55 22 19 22H5ZM5 20H19V10H5V20Z" fill="#262626"/>
                    </svg> <b>16-Jan-2023</b><br>Monday</li>
                    <li class="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM17 13H11V7H12.5V11.5H17V13Z" fill="#262626"/>
                    </svg><b>8 AM (IST)</b> <br>1hr-1:30hr/per session</li>
                    <li class="list-group-item"><button class="btn-shed" data-toggle="modal" data-target="#exampleModal"> Our courses</button></li>
                  </ul>
            </div>
        </div>
        <div class="col p-2">
            <div class="card" style="width: 100%;">
                <!-- ... Content for Card 4 ... -->
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"><b>Week Day Batches </b> <br>(mon-fri) </li>
                    <li class="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M8 14C7.71667 14 7.479 13.904 7.287 13.712C7.09567 13.5207 7 13.2833 7 13C7 12.7167 7.09567 12.479 7.287 12.287C7.479 12.0957 7.71667 12 8 12C8.28333 12 8.521 12.0957 8.713 12.287C8.90433 12.479 9 12.7167 9 13C9 13.2833 8.90433 13.5207 8.713 13.712C8.521 13.904 8.28333 14 8 14ZM12 14C11.7167 14 11.4793 13.904 11.288 13.712C11.096 13.5207 11 13.2833 11 13C11 12.7167 11.096 12.479 11.288 12.287C11.4793 12.0957 11.7167 12 12 12C12.2833 12 12.521 12.0957 12.713 12.287C12.9043 12.479 13 12.7167 13 13C13 13.2833 12.9043 13.5207 12.713 13.712C12.521 13.904 12.2833 14 12 14ZM16 14C15.7167 14 15.4793 13.904 15.288 13.712C15.096 13.5207 15 13.2833 15 13C15 12.7167 15.096 12.479 15.288 12.287C15.4793 12.0957 15.7167 12 16 12C16.2833 12 16.5207 12.0957 16.712 12.287C16.904 12.479 17 12.7167 17 13C17 13.2833 16.904 13.5207 16.712 13.712C16.5207 13.904 16.2833 14 16 14ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2.975C6 2.69167 6.09567 2.45833 6.287 2.275C6.479 2.09167 6.71667 2 7 2C7.28333 2 7.521 2.09567 7.713 2.287C7.90433 2.479 8 2.71667 8 3V4H16V2.975C16 2.69167 16.096 2.45833 16.288 2.275C16.4793 2.09167 16.7167 2 17 2C17.2833 2 17.5207 2.09567 17.712 2.287C17.904 2.479 18 2.71667 18 3V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V20C21 20.55 20.8043 21.021 20.413 21.413C20.021 21.8043 19.55 22 19 22H5ZM5 20H19V10H5V20Z" fill="#262626"/>
                    </svg> <b>16-Jan-2023</b><br>Monday</li>
                    <li class="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM17 13H11V7H12.5V11.5H17V13Z" fill="#262626"/>
                    </svg><b>8 AM (IST)</b> <br>1hr-1:30hr/per session</li>
                    <li class="list-group-item"><button class="btn-shed" data-toggle="modal" data-target="#exampleModal"> Our courses</button></li>
                  </ul>
            </div>
        </div>
        <div class="col p-3">
            <img src="../../assets/img/undraw_schedule_re_2vro (1) 1.png" style="width: 213px;
            height: 208px;
            flex-shrink: 0;">
        </div>
    </div>
  </div>
  
  
  <app-lets-create></app-lets-create>
  
  <app-more-deatils></app-more-deatils>
  
  
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" style="width: 100%; height: 100%; background: #1E4878; border-top-left-radius: 10px; border-top-right-radius: 4px">
          <h5 class="modal-title" id="exampleModalLabel" style="color: white; font-size: 22px; font-family: Montserrat; font-weight: 500; word-wrap: break-word; align-items: center;">Get Trained</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" style="color: white;">&times;</span>
          </button>
        </div>
        <form>
           <div class=" mt-3 form-group" style="margin-left: 50px;margin-right: 50px;">
              <label for="Name" class="col-form-label">Name</label>
              <input type="text" class="form-control" id="message-text" placeholder="Enter your name" style="width: 100%; background: #F3F7F9">
            </div>
            <div class=" form-group" style="margin-left: 50px;margin-right: 50px;">
              <label for="Name" class="col-form-label">Email</label>
              <input type="text" class="form-control" id="message-text" placeholder="Enter your Email" style="width: 100%; background: #F3F7F9">
            </div>
            <div class=" form-group" style="margin-left: 50px;margin-right: 50px;">
              <label for="Name" class="col-form-label">Phone</label>
              <input type="text" class="form-control" id="message-text" placeholder="Enter your phone number" style="width: 100%; background: #F3F7F9">
            </div>
            <div  style="color: #3D434F; font-size: 16px; font-family: Montserrat; font-weight: 500; word-wrap: break-word; margin-left: 50PX;">Our Course Adviser will reach you soon. <img src="../../../assets/img/noto_smiling-face.png"> </div>
        </form>
        <div  class=" mt-4 mb-4 submit-btn" style="font-size: 20px;
        font-family: Montserrat;
        font-weight: 500;
        word-wrap: break-word;">
          <button  type="button" class="btn btn-secondary" data-dismiss="modal" style="width: fit-content; margin-left: 50px;" routerLink="thanksforsubmitting">Submit</button>
        </div>
          
        
      </div>
    </div>
  </div>